// ButtonGroup.js
import React from 'react';
import { Button, ButtonGroup, Col, Row, ProgressBar } from 'react-bootstrap';

function Progress({ step, setStep, parsedJson, fileAssistants }) {
    return (
        <>
            <ButtonGroup className='d-flex w-100 mt-3'>
                <Button variant="secondary" onClick={() => setStep(1)}>Step 1</Button>
                <Button variant="secondary" onClick={() => setStep(2)} disabled={parsedJson.length === 0}>Step 2</Button>
                <Button variant="secondary" onClick={() => setStep(3)} disabled={parsedJson.length === 0 || fileAssistants.length === 0}>Step 3</Button>
                <Button variant="secondary" onClick={() => setStep(4)} disabled={parsedJson.length === 0 || fileAssistants.length === 0}>Step 4</Button>
            </ButtonGroup>
            <Row className='mb-3'>
                <Col>
                    {step === 1 && <ProgressBar variant="success" now={25} />}
                    {step === 2 && <ProgressBar variant="success" now={50} />}
                    {step === 3 && <ProgressBar variant="success" now={75} />}
                    {step === 4 && <ProgressBar variant="success" now={100} />}
                </Col>
            </Row>
        </>
    );
}

export default Progress;
