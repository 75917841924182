import React from "react";
import {useAccordionButton} from 'react-bootstrap/AccordionButton';
import {Card} from "react-bootstrap";

function CardToggle({children, eventKey, bg, text}) {
    const decoratedOnClick = useAccordionButton(eventKey, (elem) => {
        console.log(elem)
    });

    return (
        <Card
            type="button"
            style={{backgroundColor: 'whitesmoke'}}
            onClick={decoratedOnClick}
            bg={bg}
            text={text}
        >
            <Card.Body>
                {children}
            </Card.Body>
        </Card>
    );
}

export default CardToggle;