import React from "react";

function Sources() {
    return (
        <>
            <br/><br/><br/><br/><br/>
            <hr/>
            <p>
                [1] Fu, Yao, Litu Ou, Mingyu Chen, Yuhao Wan, Hao Peng, and Tushar Khot;
                2023;  Chain-of-Thought Hub: A Continuous Effort to Measure Large Language Models' Reasoning Performance;
                https://arxiv.org/pdf/2305.17306.pdf; accessed October 17. 2023
            </p>
            <p>
                [2] J. Berengueres, M. Sandell;
                2023;  Applying Standards to Advance Upstream & Downstream Ethics in Large Language Models;
                https://arxiv.org/ftp/arxiv/papers/2306/2306.03503.pdf; accessed October 17. 2023
            </p>
            <p>
                [3] Zheng, Shen, Yuyu Zhang, Yijie Zhu, Chenguang Xi, Pengyang Gao, Xun Zhou, and Kevin Chen-Chuan Chang;
                2023d;  GPT-Fathom: Benchmarking Large Language Models to Decipher the Evolutionary Path towards GPT-4 and Beyond;
                https://arxiv.org/pdf/2309.16583.pdf#page=10&zoom=100,110,702; accessed October 17. 2023
            </p>
            <p>
                [4] Keary, Tim;
                2023;  6 Best Open-Source LLMs to Watch Out For in 2024; Techopedia;
                https://www.techopedia.com/6-best-open-source-llms-to-watch-out-for-in-2024; accessed October 17. 2023

            </p>
            <p>
                [5] Mittal, Aayush;
                2023;  Understanding LLM Fine-Tuning: Tailoring Large Language Models to Your Unique Requirements. Unite.AI, September 19;
                https://www.unite.ai/understanding-llm-fine-tuning-tailoring-large-language-models-to-your-unique-requirements/; accessed October 17. 2023
            </p>
            <p>
                [6] Instagram;
                2023;  Currently, generative AI discussions focus mainly on China, the US, and the Middle East, with key players like TII (UAE), Baidu (China)… |; Instagram
            </p>
            <p>
                [7] Edward Beeching, Clémentine Fourrier, Nathan Habib, Sheon Han, Nathan Lambert, Nazneen Rajani, Omar Sanseviero, Lewis Tunstall, Thomas Wolf;
                2023;  Open LLM Leaderboard; Electronic document,
                https://huggingface.co/spaces/HuggingFaceH4/open_llm_leaderboard; accessed October 17. 2023
            </p>
            <p>
                [8] LMSYS;
                2023;  Chatbot Arena Leaderboard - a Hugging Face Space by lmsys. Electronic document;
                https://huggingface.co/spaces/lmsys/chatbot-arena-leaderboard; accessed October 17. 2023
            </p>
        </>
    )
}

export default Sources;