import stringSimilarity from 'string-similarity';

export const candidateStrings = [
    "kein ähnliches oder vergleichbares Produkt", 
    "Ähnliches Produkt", 
    "Äquivalenz-Produkt",
    "Überwachtes Produkt",
    "Keine Problembeschreibung vorhanden",
    "Problem ist ausführlich beschrieben (mehrere Sätze, die die Situation beschreiben)",
    "Problem ist wage/unzureichend beschrieben",
    "Problem wird noch untersucht oder Stellungnahme des Herstellers ist unzureichend für eine Bewertung",
    "FSN zu einer FSCA (Rückruf, Umtausch, Zerstörung, Bedienungshinweise, etc.)",
    "Keine Stellungnahme des Herstellers/Maßnahme ersichtlich",
    "Nein",
    "Ja",
    "Äquivalentes Produkt",
    "andere Produktgruppe/kein konkretes Produkt benannt",
    "keine konkreten Informationen zu Sicherheit/Leistung/Performance/State of the Art",
    "Informationen zur Sicherheit",
    "Informationen zum State of the Art",
    "Informationen zu Leistung/Performance",
    "Informationen zu Sicherheit und Leistung/Performance",
  ];

  export const stringScores = [0, 5, 10, 15, 0, 15, 5, 5, 15, 0, 0, 15, 10, 0, 0, 10, 5, 10, 15];

  export const labelScore = (adapter, score) => {
    let label = "";
    let scoreSmooth = 0;
    adapter = parseInt(adapter)
  
    switch (adapter) {
      case 1:
      case 2:
      case 4:
      case 7:
      case 9:
      case 11:
        if (score >= 0 && score <= 14) {
          scoreSmooth = 0;
          label = "Not relevant";
        } else if (score >= 15 && score <= 29) {
          scoreSmooth = 1;
          label = "Eventually relevant";
        } else if (score >= 30 && score <= 999) {
          scoreSmooth = 2;
          label = "Relevant";
        }
        break;
  
      case 3:
        if (score >= 0 && score <= 20) {
          scoreSmooth = 0;
          label = "Not relevant";
        } else if (score >= 21 && score <= 30) {
          scoreSmooth = 1;
          label = "Eventually relevant";
        } else if (score >= 31 && score <= 999) {
          scoreSmooth = 2;
          label = "Relevant";
        }
        break;
  
      case 8:
        if (score >= 0 && score <= 15) {
          scoreSmooth = 0;
          label = "Not relevant";
        } else if (score >= 16 && score <= 29) {
          scoreSmooth = 1;
          label = "Eventually relevant";
        } else if (score >= 30 && score <= 999) {
          scoreSmooth = 2;
          label = "Relevant";
        }
        break;
  
      default:
        label = "Invalid id";
    }
  
    return {
      scoreSmooth: scoreSmooth,
      label: label
    };
  }

  export const calculateTotalScore = (targetString, candidateStrings, stringScores) => {
    let totalScore = 0
    candidateStrings.forEach(candidate => {
      const similarity = stringSimilarity.compareTwoStrings(targetString, candidate);
      if (similarity >= 0.9) {
        totalScore += stringScores[candidateStrings.indexOf(candidate)];
      }
    });
    return totalScore;
  };