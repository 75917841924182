// Subpage.js
import React, { useState } from 'react';
import { Container, Col, Row, Button } from 'react-bootstrap';
import './global.css';
import Chapter from '../component/Chapter';
import ButtonGroup from '../component/ButtonGroup';
import FileUploadSection from '../component/FileUploadSection';
import Progress from '../component/Progress';
import CER from '../component/CER';
import Feedback from '../component/Feedback';

const CaSum = () => {
  const [step, setStep] = useState(1);
  const [fileAssistants, setFileAssistants] = useState([]);
  const [parsedJson, setParsedJson] = useState([]);
  const [documentText, setDocumentText] = useState('');
  const [documentStructure, setDocumentStructure] = useState([]);
  const [title, setTitle] = useState('Title');
  const [summaries, setSummaries] = useState({});
  const [chapterText, setChapterText] = useState([]);
  const [chapterDone, setChapterDone] = useState({});
  const [chapterMergedText, setChapterMergedText] = useState({});

  //const baseUrl = "/api2"; //prod
  const baseUrl = "http://localhost:8100"; //dev

  const updateSummaries = (chapterId, assistantId, data) => {
    setSummaries(prevSummaries => {
      // Create a new object representing the updated state
      const updatedSummaries = { ...prevSummaries };

      // Ensure that summaries[chapterId] is initialized
      if (!updatedSummaries.hasOwnProperty(chapterId)) {
        updatedSummaries[chapterId] = {};
      }

      // Update the state with the new data
      updatedSummaries[chapterId][assistantId] = data;

      return updatedSummaries;
    });
  };

  const handleParsedJson = (json) => {
    setParsedJson(json);
  };

  const fillExampleChapters = () => {
    //setDocumentText('1 Heading\n  1.1 Subheading\n  1.2 Subheading\n    1.2.1 Subsubheading\n2 Chapter')
    setDocumentText('1 Technical\n  1.1 Product Standard\n    1.1.1 Scope of Application\n    1.1.2 Stimulating Parameters (Limits)\n    1.1.3 Accuracy Requirements for Parameters\n  1.2 Product Technology (Comparison with Similar Products)\n    1.2.1 Functions (EMG Triggering)\n    ...\n  2 Similar Products\n    ...')
  }

  const fillExampleFiles = () => {
    setFileAssistants(
      [
        {
          "assistantId": "asst_5U7IoiERpOHEr4SPXQWgUnrg",
          "threadId": "thread_zDJB6YVJP3Prbx5LAuAFwo7e",
          "filename": "01_2009-Elhanbly.pdf",
          "fileId": "file-wLmWixRZFbtC1te4Ty7M3Y4j"
        },
        {
          "assistantId": "asst_EZVP7Tac1G7wsJ6D1BUAv7ml",
          "threadId": "thread_pHVhzMeB4vHDNP0cKeAnJ57o",
          "filename": "04_2021_Salonia.pdf",
          "fileId": "file-isqfrSO0FcVX2i1NTvGtF4ca"
        },
        {
          "assistantId": "asst_FLQfxXZYiTbKxHhQIjL7hAUc",
          "threadId": "thread_kq4rxVsZy8eWnzzII1di1TDp",
          "filename": "05_2018_Hackett.pdf",
          "fileId": "file-2P8XybwlKqExeS6Mv6uV0Qes"
        }
      ]
    )
  }

  return (
    <>
      <Container>
        <Row>
          <Col className={'mt-4 navigator'}>
            <a href="/">LLM Radar</a>
            <img src="/breadcrumb.svg" height="10" className="mx-3" alt="Pfeil rot rechts" />
            <a href="/ca">CA Showcase</a>
          </Col>
        </Row>
        <Row>
          <Col>
            <Progress step={step} setStep={setStep} parsedJson={parsedJson} fileAssistants={fileAssistants} />
          </Col>
        </Row>
        {step === 1 &&
          < >
            <Row className='mt-3'>
              <Col>
                <Chapter onParsedJson={handleParsedJson} documentText={documentText} setDocumentText={setDocumentText} 
                  documentStructure={documentStructure} setDocumentStructure={setDocumentStructure} fillExampleChapters={fillExampleChapters} />
              </Col>
            </Row>
            <Row>
              <Col>
                <ButtonGroup step={step} setStep={setStep} parsedJson={parsedJson} />
              </Col>
            </Row>
          </>
        }
        {step === 2 &&
          <>
            <Row className='mt-3'>
              <Col>
                <FileUploadSection
                  fillExampleFiles = {fillExampleFiles}
                  fileAssistants={fileAssistants}
                  setFileAssistants={setFileAssistants} baseUrl={baseUrl}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <ButtonGroup step={step} setStep={setStep} fileAssistants={fileAssistants} />
              </Col>
            </Row>
          </>

        }
      </Container>
      <Container fluid>
        {step === 3 &&
          <>
            <Row className='mt-3'>
              <CER fileAssistants={fileAssistants} parsedJson={parsedJson} title={title} setTitle={setTitle} updateSummaries={updateSummaries} 
                summaries={summaries} chapterText={chapterText} setChapterText={setChapterText} chapterDone={chapterDone} setChapterDone={setChapterDone}
                chapterMergedText={chapterMergedText} setChapterMergedText={setChapterMergedText} baseUrl={baseUrl} />
            </Row>
            <Row>
              <Col>
                <ButtonGroup step={step} setStep={setStep} />
              </Col>
            </Row>
          </>
        }
      </Container>
      <Container>
      {step === 4 &&
          <>
            <Row className='mt-3'>
              <Col className='text-center'>
                <Button variant="success" type="submit"onClick={() => console.log("download")}>
                  Download Pdf (not in this version yet)
                </Button>
              </Col>
            </Row>
            <Row className='mt-4'>
              <Feedback />
            </Row>
            <Row>
              <Col>
                <ButtonGroup step={step} setStep={setStep} />
              </Col>
            </Row>
          </>
        }
      </Container>
    </>
  );
};

export default CaSum;
