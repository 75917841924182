import React from 'react';
import { useState } from 'react';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import axios from 'axios';

function FileUploadSection({ fileAssistants, setFileAssistants, fillExampleFiles, baseUrl }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleDeleteFileAssistant = (index) => {
    if(fileAssistants[index].filename === "filename1.pdf" || fileAssistants[index].filename === "filename2.pdf") {
      const newFileAssistants = [...fileAssistants];
      newFileAssistants.splice(index, 1);
      setFileAssistants(newFileAssistants);
      return
    }

    const assistantId = fileAssistants[index].assistantId;
    const fileId = fileAssistants[index].fileId;
    try {
      axios.post(baseUrl + '/delete_assistant', {
        assistant_id: assistantId,
        file_id: fileId
      });
    } catch (error) {
      console.error('Error deleting file:', error);
    }
    const newFileAssistants = [...fileAssistants];
    newFileAssistants.splice(index, 1);
    setFileAssistants(newFileAssistants);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    setLoading(true);
    if (!selectedFile) {
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await axios.post(baseUrl + '/create_assistant', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setFileAssistants([...fileAssistants, {
        "assistantId": response.data.assistant_id,
        "threadId": response.data.thread_id,
        "filename": response.data.filename,
        "fileId": response.data.file_id
      }]);
      setLoading(false);
    } catch (error) {
      console.error('Error uploading file:', error);
      setLoading(false);
    }
  };

  return (
    <Card className='p-3'>
      <h3 className='text-center'>Input files</h3>
      {fileAssistants.map((fileAssistant, index) => (
        <div key={index} className='d-flex align-items-center'>
          <p className='ms-1 mb-1'>{fileAssistant.filename}</p>
          <Button variant='danger' className='ms-2 pt-0 pb-0' onClick={() => handleDeleteFileAssistant(index)}>x</Button>
        </div>
      ))}
      <Form.Group controlId="formFile" className="mb-3">
        <Form.Control type="file" onChange={handleFileChange} />
        <Button onClick={fillExampleFiles}   style={{ background: 'none', border: 'none', color: 'blue', textDecoration: 'underline', cursor: 'pointer', padding: 0 }}>Add example files</Button>
      </Form.Group>
      <Button variant="primary" onClick={handleUpload} disabled={!selectedFile || loading}>
        {loading ? <Spinner animation="border" size="sm" role="status" aria-hidden="true" /> : 'Upload'}
      </Button>
    </Card>
  );
}

export default FileUploadSection;
