// examples.js
export const examples = [
    { identifier: 'example0', label: 'None', tags: '', text: '', score: 0, adapter: 0, link: '' },
    { 
      identifier: '0008-33513950',
      label: 'Example 1 (pubmed)', 
      tags: 'Legionellose,In-vitro Diagnostikum,Enzymimmunoassay,Legionella pneumophila Serogruppe 1 Lipopolysaccharid,Allplex™ Respiratory Panel 4,IVD,Legionella pneumophila Serogruppe 1-induzierte Pneumonie,Enzyme-linked Immunosorbent Assay,Lipopolysaccharide of Legionella pneumophila serogroup 1,enzyme immunoassay,In vitro diagnostic medical device,Diagnose,RIDA®GENE CAP BAC,Legionnaire’s disease,Legionella pneumophila,In vitro diagnostics,Legionellosis,Diagnosis,Binax™ Legionella Urinary Antigen EIA,Legionella pneumophila serogroup 1 induced pneumonia,EIA,ELISA,L. pneumophila', 
      text: 'The detection of water contamination with Legionella pneumophila is of critical importance to manufacturers of water processing equipment and public health entities dealing with water networks and distribution systems. Detection methods based on polymerase chain reaction or biosensor technologies require preconcentration steps to achieve attractive sensitivity levels. Preconcentration must also be included in protocols of automated collection of water samples by systems designed for quasi-continuous monitoring of remotely located water reservoirs for the presence of L. pneumophila. We designed and characterized a water sampling module for filtration and backwashing intended for analysis of low-to-medium contaminated water, typically with L. pneumophila bacteria not exceeding 50 colony-forming units per milliliter. The concentration factors of 10× and 21× were achieved with 0.22 and 0.45 µm filters, respectively, for samples of bacteria prepared in clean saline solutions. However, a 5× concentration factor was achieved with 0.45 µm filters for a heavily contaminated or turbid water typical of some industrial water samples.',
      score: 1,
      score_raw: 25,
      adapter: 8,
      link: 'https://pubmed.ncbi.nlm.nih.gov/33513950/'
    },
    { 
      identifier: '0004-11814258',
      label: 'Example 2 (fda maude)',
      tags: 'Corpuls CPR,CPR technology,Lucas 3,AutoPulse,Lucas 2,use error,EASY PULSE,automatic CPR,chest compression system,automated CPR,mechanical CPR,mechanisches Thoraxkompressionsgerät,injuries,injury,adverse events,Cardiopulmonary resuscitation,CPR', 
      text: `"REMOVAL_CORRECTION_NUMBER ->

REPORT_TO_FDA ->

DISTRIBUTOR_CITY ->

MANUFACTURER_G1_COUNTRY_CODE -> US

MANUFACTURER_CONTACT_PLOCAL -> 4258674000

MANUFACTURER_ZIP_CODE_EXT ->

DATE_MANUFACTURER_RECEIVED -> 11/09/2021

ADVERSE_EVENT_FLAG -> Y

EVENT_TYPE -> Death

MANUFACTURER_CONTACT_T_NAME -> MR.

HEALTH_PROFESSIONAL -> Y

DATE_CHANGED -> 10/25/2023

DATE_OF_EVENT -> 04/01/2021

MANUFACTURER_CONTACT_ZIP_CODE -> 98052

REPORT_SOURCE_CODE -> Manufacturer report

REPORT_TO_MANUFACTURER ->

PMA_PMN_NUM -> K062401

MANUFACTURER_CONTACT_PCITY -> 42586740

DISTRIBUTOR_ZIP_CODE_EXT ->

TYPE_OF_REPORT -> Initial submission,Followup,Followup

DEVICE_DATE_OF_MANUFACTURE ->

NUMBER_DEVICES_IN_EVENT ->

MANUFACTURER_G1_ZIP_CODE_EXT ->

SUMMARY_REPORT -> N

EVENT_KEY ->

MANUFACTURER_CONTACT_COUNTRY -> US

MANUFACTURER_ADDRESS_1 ->

MANUFACTURER_G1_CITY -> REDMOND

INITIAL_REPORT_TO_FDA -> No

MANUFACTURER_LINK_FLAG_ -> Y

MANUFACTURER_CONTACT_PCOUNTRY ->

MANUFACTURER_CONTACT_POSTAL -> 98052

NUMBER_PATIENTS_IN_EVENT ->

DISTRIBUTOR_ADDRESS_1 ->

MANUFACTURER_CONTACT_ZIP_EXT ->

PREVIOUS_USE_CODE -> R

DATE_REPORT -> 12/03/2021

MANUFACTURER_G1_ZIP_CODE -> 98052

MANUFACTURER_G1_STREET_2 -> IDEON SCIENCE PARK

MANUFACTURER_CONTACT_F_NAME -> TODD

MANUFACTURER_G1_POSTAL_CODE -> 98052

MANUFACTURER_CONTACT_STATE -> WA

NOE_SUMMARIZED -> 1

MANUFACTURER_COUNTRY_CODE ->

MANUFACTURER_CONTACT_STREET_1 -> 11811 WILLOWS ROAD NE

MANUFACTURER_CONTACT_CITY -> REDMOND

DISTRIBUTOR_STATE_CODE ->

REPORT_NUMBER -> 0003015876-2021-01044

DATE_REPORT_TO_MANUFACTURER ->

EXEMPTION_NUMBER ->

REPORTER_OCCUPATION_CODE -> PHYSICIAN

MANUFACTURER_ZIP_CODE ->

DATE_FACILITY_AWARE ->

MANUFACTURER_CONTACT_AREA_CODE -> 425

REPORTER_COUNTRY_CODE -> EI

MANUFACTURER_G1_STREET_1 -> 11811 WILLOWS ROAD NE

REPORT_DATE ->

MANUFACTURER_POSTAL_CODE ->

MANUFACTURER_STATE_CODE ->

EVENT_LOCATION ->

MANUFACTURER_NAME ->

DISTRIBUTOR_ADDRESS_2 ->

SINGLE_USE_FLAG -> N

DATE_RECEIVED -> 05/12/2021

MDR_REPORT_KEY -> 11814258

PRODUCT_PROBLEM_FLAG -> N

REPROCESSED_AND_REUSED_FLAG -> N

SOURCE_TYPE -> Foreign

MANUFACTURER_G1_NAME -> PHYSIO-CONTROL, INC. - 3015876

DATE_REPORT_TO_FDA ->

MANUFACTURER_CONTACT_STREET_2 ->

MANUFACTURER_CONTACT_EXTENSION ->

DATE_ADDED -> 05/12/2021

DISTRIBUTOR_ZIP_CODE ->

MANUFACTURER_CITY ->

MANUFACTURER_ADDRESS_2 ->

DISTRIBUTOR_NAME ->

MANUFACTURER_CONTACT_EXCHANGE -> 425

MANUFACTURER_CONTACT_PHONE_NO -> 4258

REMEDIAL_ACTION ->

MANUFACTURER_G1_STATE_CODE -> WA

MANUFACTURER_CONTACT_L_NAME -> BANDY

MDR_TEXT_KEY -> 250268989

PATIENT_SEQUENCE_NUMBER -> 1

DATE_REPORT ->

MDR_REPORT_KEY -> 11814258

TEXT_TYPE_CODE -> N

FOI_TEXT -> PHYSIO-CONTROL CONTACTED THE CUSTOMER TO REQUEST ADDITIONAL INFORMATION ON THE PATIENT. NO RESPONSE HAS BEEN RECEIVED FROM THE CUSTOMER. PATIENT FIELDS IN WHICH INFORMATION IS NOT PROVIDED WERE INTENTIONALLY LEFT BLANK. PHYSIO-CONTROL WILL NOT REQUEST ANY PATIENT IDENTIFYING INFORMATION TO BE IN ACCORDANCE WITH REGULATION (EU) (B)(4) OF THE EUROPEAN PARLIAMENT AND OF THE COUNCIL.

MDR_TEXT_KEY -> 250268990

PATIENT_SEQUENCE_NUMBER -> 1

DATE_REPORT ->

MDR_REPORT_KEY -> 11814258

TEXT_TYPE_CODE -> D

FOI_TEXT -> A CUSTOMER CONTACTED PHYSIO-CONTROL TO REPORT THAT SOME INJURIES ASSOCIATED WITH RESUSCITATION WERE NOTED AT POST-MORTEM OF THE PATIENT. IT WAS OBSERVED THAT THE PATIENT'S CHEST CAGE HAD COLLAPSED AND HAD BROKEN RIBS. THE PATIENT ASSOCIATED WITH THE REPORTED EVENT DID NOT SURVIVE.

MDR_TEXT_KEY -> 325342517

PATIENT_SEQUENCE_NUMBER -> 0

DATE_REPORT ->

MDR_REPORT_KEY -> 11814258

TEXT_TYPE_CODE -> N

FOI_TEXT -> A PHYSIO-CONTROL SALES REP CONFIRMED THAT THE SERIAL NUMBER ASSOCIATED WITH THE REPORTED EVENT IS SN (B)(6) . SECTION D1 AND D4 HAVE BEEN UPDATED ACCORDINGLY.

MDR_TEXT_KEY -> 325342518

PATIENT_SEQUENCE_NUMBER -> 0

DATE_REPORT ->

MDR_REPORT_KEY -> 11814258

TEXT_TYPE_CODE -> D

FOI_TEXT -> A CUSTOMER CONTACTED PHYSIO-CONTROL TO REPORT THAT SOME INJURIES ASSOCIATED WITH RESUSCITATION WERE NOTED AT POST-MORTEM OF THE PATIENT. IT WAS OBSERVED THAT THE PATIENT'S CHEST CAGE HAD COLLAPSED AND HAD BROKEN RIBS. THE PATIENT ASSOCIATED WITH THE REPORTED EVENT DID NOT SURVIVE.

MDR_TEXT_KEY -> 326512283

PATIENT_SEQUENCE_NUMBER -> 0

DATE_REPORT ->

MDR_REPORT_KEY -> 11814258

TEXT_TYPE_CODE -> N

FOI_TEXT -> STRYKER EVALUATED THE CUSTOMER'S DEVICE AND OBSERVED NO DEVICE MALFUNCTION. THE REPORTED ISSUE COULD NOT BE VERIFIED. AFTER PERFORMING OTHER UNRELATED REPAIRS, PROPER DEVICE OPERATION WAS OBSERVED THROUGH FUNCTIONAL AND PERFORMANCE TESTING. THE DEVICE WAS SUBSEQUENTLY RETURNED TO THE CUSTOMER FOR USE. THE CAUSE OF THE REPORTED ISSUE COULD NOT BE DETERMINED, HOWEVER BROKEN RIBS AND STERNAL FRACTURE IS A SIDE AFFECT OF CPR AND THE USE OF THE LUCAS DEVICE. PER THE OI, THE STABILIZATION STRAPS SHOULD BE USED IN EACH EVENT. IT IS UNKNOWN IF THE STRAPS WERE USED DURING THIS PATIENT EVENT. IF THEY WEREN'T, THAT COULD HAVE CONTRIBUTED TO THE REPORTED PATIENT OUTCOME.

MDR_TEXT_KEY -> 326512284

PATIENT_SEQUENCE_NUMBER -> 0

DATE_REPORT ->

MDR_REPORT_KEY -> 11814258

TEXT_TYPE_CODE -> D

FOI_TEXT -> A CUSTOMER CONTACTED PHYSIO-CONTROL TO REPORT THAT SOME INJURIES ASSOCIATED WITH RESUSCITATION WERE NOTED AT POST-MORTEM OF THE PATIENT. IT WAS OBSERVED THAT THE PATIENT'S CHEST CAGE HAD COLLAPSED AND HAD BROKEN RIBS. THE PATIENT ASSOCIATED WITH THE REPORTED EVENT DID NOT SURVIVE.

MANUFACTURER_D_ZIP_CODE_EXT -> 3 70

MANUFACTURER_D_STATE_CODE ->

IMPLANT_FLAG ->

DATE_REMOVED_FLAG ->

MODEL_NUMBER -> LUCAS

COMBINATION_PRODUCT_FLAG -> N

MANUFACTURER_D_ADDRESS_1 -> SCHEELEVAGEN 17

GENERIC_NAME -> COMPRESSOR, CARDIAC, EXTERNAL

MANUFACTURER_D_POSTAL_CODE -> SE-223 70

DEVICE_SEQUENCE_NO -> 1

MANUFACTURER_D_ZIP_CODE -> SE-22

DATE_RETURNED_TO_MANUFACTURER -> 2021/07/26

OTHER_ID_NUMBER ->

MANUFACTURER_D_COUNTRY_CODE -> SW

MANUFACTURER_D_ADDRESS_2 -> IDEON SCIENCE PARK

BRAND_NAME -> LUCAS 3 CHEST COMPRESSION SYSTEM

DEVICE_AGE_TEXT -> DA

CATALOG_NUMBER -> UNK_LUN

DEVICE_OPERATOR -> HEALTH PROFESSIONAL

DATE_RECEIVED -> 2021/05/12

LOT_NUMBER ->

DEVICE_EVENT_KEY ->

MDR_REPORT_KEY -> 11814258

MANUFACTURER_D_CITY -> LUND

EXPIRATION_DATE_OF_DEVICE ->

DEVICE_AVAILABILITY -> R

DEVICE_EVALUATED_BY_MANUFACTUR -> Y

DEVICE_REPORT_PRODUCT_CODE -> DRM

MANUFACTURER_D_NAME -> JOLIFE AB - 3005445717"`,
      score: 0,
      score_raw: 0,
      adapter: 4,
      link: 'https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfmaude/detail.cfm?mdrfoi__id=11814258&pc=DRM'
    },
    { 
      identifier: '0011-NCT04648111',
      label: 'Example 3 (ctgov)', 
      tags: 'patient management,website,mobile application,consulting,documentation,europe,side effect,device-induced,reliability,mobile,treatment,died,heart failure,complication,apps,safety,state of the art,death,mobile app,imaging,accuracy,malfunction,german,Cora Health,mortality,review,tele medicine,digital health,iOS,medical device,impaired,coaching,cell phone,web,complaint,blood pressure data,AVAX Blutdruck,smart phone,risk,software,mobile health,device safety,self-management,medication,smart phone app,effectiveness,failure,data,BlutdruckDaten,guideline,mHealth,mobile phone,SaniQHeart,data management,chronic heart failure,device failure,therapy,mobile technolog,android,adult,mobile device,hazardous,overview,adverse device effect,coach,medication registration,applicable standard,device,app,injury,registration,computerized,guidance document,Blutdruckpass', 
      text: `"NCT04648111
2022-11-02T00:00:00.000Z
Completed
Implementing Peri-operative Mobile Application for Contactless Vital Signs
2020-11-13T00:00:00.000Z
2020-12-01T00:00:00.000Z
2021-06-04T00:00:00.000Z
2021-11-30T00:00:00.000Z
Actual
Implementing Peri-operative Mobile Application for Contactless Screening and Vital Signs Measurement (MAC-VITAL): A Proof of Concept Trial
Observational
91.0
Actual
2021-11-30T00:00:00.000Z
Actual
0
This study is a prospective proof of concept study to be conducted at Toronto Western Hospital,Toronto General Hospital, Hamilton General Hospital (Hamilton, Ontario), Juravinski Hospital (Hamilton, Ontario) and Trillium Health Partners, Mississauga, Ontario. This proof of concept study will determine the feasibility and effectiveness of implementing a contactless mobile application to obtain vital signs from patients in the peri-operative setting. Informed consent to participate in the study will be obtained.

Vital signs measurement will be obtained by the research staff using hospital standard medical devices and the Anura Research App will also be used to measure vital signs. Measurement will take place before surgery and post surgery (1 ±2hrs and 2 ±4hrs after surgery).
The huge impact of the COVID-19 pandemic on global healthcare systems has prompted search for novel tools to stem the tide. New digital health tools can provide possible health solutions in this time of unprecedented medical crisis to mitigate the impact of this pandemic. This proof of concept study will determine the feasibility and effectiveness of implementing a mobile application for contactless measurement of vital signs (MAC-VITAL) such as blood pressure (BP), heart rate (HR), respiratory rate (RR) and oxyhemoglobin saturation (SpO2) from surgical patients peri-operatively. Contactless measurement of vital signs will bridge the current gap between virtual care and in-person medical assessments. This study aims to determine whether a mobile app can effectively measure vital signs without any person to person contact and how this technology can be implemented in a peri-operative setting during COVID-19.
None
None
Cohort
None
Prospective
None
None
None
None
None
None
None
Principal Investigator
Dr. Frances Chung
Anesthesiologist
None
University Health Network, Toronto
primary
Vital signs measurement
24 hours
None
Blood pressure will be measured by using both AnuraTM research app and standard medical grade Blood pressure machine pre-and post-operatively.
primary
Vital signs measurement
24 hours
None
Heart rate will be measured by using both AnuraTM research app and standard medical grade Blood pressure machine pre-and post-operatively.
primary
Vital signs measurement
24 hours
None
Breathing rate will be measured by using both AnuraTM research app and standard medical grade Blood pressure machine pre-and post-operatively.
primary
Vital signs measurement
24 hours
None
Heart rate variability will be measured by using both AnuraTM research app and standard medical grade Blood pressure machine pre-and post-operatively.
primary
Vital signs measurement
24 hours
None
Oxygen saturation will be measured by using both AnuraTM research app and standard medical grade Blood pressure machine pre-and post-operatively.
None
399 Bathurst St.,Toronto Western Hopsital, Preadmission Clinic, Dept. of Anesthesia
Toronto
Ontario
M5T2S8
Canada
Canada
Diagnostic Test
Vital sign measurement
Vital signs measurement will be obtained by the research staff using hospital standard medical devices and the Anura Research App will also be used to measure vital signs. Measurement will take place before surgery and post surgery (1 ±2hrs and 2 ±4hrs after surgery).
Blood Pressure, Heart Rate, Breathing Rate and Oxygen Level
Respiration Disorders
Respiratory Tract Diseases
Pathologic Processes
Respiratory Aspiration
https://clinicaltrials.gov/ct2/show/NCT04648111
Non-Probability Sample
All
18 Years
N/A
No
Patients scheduled to undergoing elective inpatient surgery under general and/or regional anesthesia
None
None
inclusion criteria

patients scheduled to undergoing elective inpatient surgery under general and/or regional anesthesia
18 years or older

      "`,
      score: 0,
      score_raw: 0,
      adapter: 11,
      link: 'https://clinicaltrials.gov/ct2/show/NCT04648111?term=Cora+Health+OR+BlutdruckDaten+OR+SaniQHeart+OR+Blutdruckpass+OR+AVAX+Blutdruck+OR+smart+phone+app&cond=blood+pressure&lupd_s=06%2F01%2F2021&draw=2&rank=1'
    },
    { 
      identifier: '0003-CVE-2022-32287',
      label: 'Example 4 (nist)', 
      tags: 'whole body,libunistring,opkg-utils,qtquickcontrols,zip,wireless-regdb,modutils-initscripts,sqlite3-autoconf,procps-ng,qtserialport,4.14.78,udev-extraconf,2.21,ncurses,libwebp,WB,EMS,STM32 F3 Cube HAL Driver,Elektromyostimulation,speexdsp,miha bodytec,update-rc.d,qtlocation,pango,10.2.1,electrical,openssl,qtwebsockets,shadow,taglib,1.10.0,pixman,libvorbis,speex,qtbase,qtdeclarative,psplash,qtxmlpatterns,pcre,lzo,sed,Whole-body,elektrisch,nspr,tiny-AES-c,electric,Myostimulation,xz,orc,electromyostimulation,libxml2,nss,sysvinit-inittab,which,pulseaudio,libtirpc,Muskelstimulation,shadow-sysroot,qtmultimedia,FreeRTOS,libxcrypt,netbase,nettle,shadow-securetty,4.3,nano,nss-mdns,ARM Cortex M4 CMSIS,libusb,socat,qtwebengine,qtquickcontrols2,util-linux,popt,wpa-supplicant,muscle stimulation,sysvinit,libusb-compat,unzip,rsync,myostimulation,BSD-3-Clause,u-boot-mbt2.1,libtool,readline,twn4flash,linux,run-postinsts,qtwebchannel,printf,shared-mime-info', 
      text: `"CVE
MITRE
4.0
CVE-2022-32287
security@apache.org
en
CWE-22
https://lists.apache.org/thread/57vk0d79j94d0lk0vol8xn935yv1shdd
https://lists.apache.org/thread/57vk0d79j94d0lk0vol8xn935yv1shdd
MISC
Mailing List
Vendor Advisory
http://www.openwall.com/lists/oss-security/2022/11/03/4
[oss-security] 20221103 CVE-2022-32287: Apache UIMA prior to 3.3.1 has a path traversal vulnerability when extracting (PEAR) archives
MLIST
Mailing List
Third Party Advisory
en
A relative path traversal vulnerability in a FileUtil class used by the PEAR management component of Apache UIMA allows an attacker to create files outside the designated target directory using carefully crafted ZIP entry names. This issue affects Apache UIMA Apache UIMA version 3.3.0 and prior versions. Note that PEAR files should never be installed into an UIMA installation from untrusted sources because PEAR archives are executable plugins that will be able to perform any actions with the same privileges as the host Java Virtual Machine.
4.0
OR
[]
True
cpe:2.3:a:apache:uimaj:*:*:*:*:*:*:*:*
3.3.0
[]
3.1
CVSS:3.1/AV:N/AC:L/PR:N/UI:N/S:U/C:N/I:H/A:N
NETWORK
LOW
NONE
NONE
UNCHANGED
NONE
HIGH
NONE
7.5
HIGH
3.9
3.6
2022-11-03T12:15Z
2023-05-22T15:44Z"
      `,
      score: 1,
      score_raw: 25,
      adapter: 3,
      link: 'https://nvd.nist.gov/vuln/detail/CVE-2022-32287'
    },
  ];