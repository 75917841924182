export const extract_answer = (json) => {
    return json.answers.map((answerObj) => {
    const match = answerObj.answer.match(/^Antwort: (.*)/);

    return {
        ...answerObj,
        answer: match ? match[1] : answerObj.answer,
    };
    });
}

export const calculateTokenCost = (text) => {
    let costPer1KTokens = 0.01
    let charsPerToken = 4;
    let tokenSize = Math.ceil(text.length / charsPerToken);

    // Calculate the cost based on the number of tokens and cost per 1K tokens

    let cost = (tokenSize / 1000) * costPer1KTokens * 3; // "* 3" because of 3x scoring question input
    cost += 0.03 * 3 // cost for 3 scoring question output
    cost += 0.01 * 3 // cost for 3 json transformation input
    cost += 0.03 * 3 // cost for 3 json transformation output

    return cost;
}

export const calculateTokenCostv3 = (text) => {
    let costPer1KTokens = 0.01
    let charsPerToken = 4;
    let tokenSize = Math.ceil(text.length / charsPerToken);

    // Calculate the cost based on the number of tokens and cost per 1K tokens

    let cost = (tokenSize / 1000) * costPer1KTokens * 3; // "* 3" because of 3x scoring question input
    cost += 0.03 * 3 // cost for 3 scoring question output

    return cost;
}