import './App.css';
import Dashboard from "./page/Dashboard";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PmsEval from './page/PmsEval';
import PmsEvalAlpine from './page/PmsEvalAlpine';
import LlmMap from './page/LlmMap';
import Pms from './layout/Pms';
import Llm from './layout/Llm';
import Ca from './layout/Ca';
import Radar from './layout/Radar';
import CaSum from './page/CaSum';

function App() {
  return (
    <div className="App">
        <Router>
          <Routes>
            <Route path="/" exact element={<Radar><Dashboard /></Radar>} />
            <Route path="/llm" exact element={<Llm><LlmMap /></Llm>} />
            <Route path="/pms" element={<Pms version={'v4'}><PmsEval /></Pms>} />
            <Route path="/pms4" element={<Pms version={'v4'}><PmsEval /></Pms>} />
            <Route path="/pms-a" element={<Pms version={'v4-alpine'}><PmsEvalAlpine /></Pms>} />
            <Route path="/ca" element={<Ca version={'ca'}><CaSum /></Ca>} />
          </Routes>
        </Router>
    </div>
  );
}

export default App;
