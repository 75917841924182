import React, { useState } from 'react';

function TruncateText({ text, maxLength = 100 }) {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleText = () => {
        setIsExpanded(!isExpanded);
    };

    const truncatedText = isExpanded ? text : text.slice(0, maxLength);

    return (
        <div>
            <div dangerouslySetInnerHTML={{ __html: truncatedText }} />
            {text.length > maxLength && (
                <span style={{ color: 'blue', cursor: 'pointer' }} onClick={toggleText}>
          {isExpanded ? '...Read Less' : '...Read More'}
        </span>
            )}
        </div>
    );
}

export default TruncateText;
