import React from "react";
import {Card} from "react-bootstrap";
function MetricCollapse({ children }) {
    return (
        <Card
            type="button"
            className={'mt-3'}
        >
            <Card.Body>
                {children}
            </Card.Body>
        </Card>
    );
}

export default MetricCollapse;