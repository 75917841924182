import {Col, Container, Row, Badge, Card, Form} from "react-bootstrap";
import llmData from '../data/llm-meta.json';
import MetricAccordion from "../component/MetricAccordion";
import SumAccordion from "../component/SumAccordion";
import TruncateText from "../component/TruncateText";
import { BoxArrowUpRight } from 'react-bootstrap-icons';
import React, { useState, useCallback } from 'react';
import ImageViewer from 'react-simple-image-viewer';
import Accordion from "react-bootstrap/Accordion";
import './global.css';
import Sources from "../component/Sources";
import Introduction from "../component/Introduction";

function LlmView() {
    const llmXs = 12;
    const llmMd = 6;
    const llmLg = 4;
    const llmClass = "my-3";

    const [sortBy, setSortBy] = useState('mmlu'); // Default sorting metric
    const [sortOrder, setSortOrder] = useState('desc'); // Default sorting order (ascending)
    const [filterBy, setFilterBy] = useState('all'); // Default: Show all items
    const [sourceFilter, setSourceFilter] = useState('all'); // Default: Show all sources
    const [searchTerm, setSearchTerm] = useState('');

    const handleSortByChange = (event) => {
        setSortBy(event.target.value);
    };

    const handleSortOrderChange = (event) => {
        setSortOrder(event.target.value);
    };

    const handleFilterChange = (event) => {
        setFilterBy(event.target.value);
    };

    const handleSourceFilterChange = (event) => {
        setSourceFilter(event.target.value);
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const sortData = (dataToSort) => {
        const sortedData = [...dataToSort];
        sortedData.sort((a, b) => {
            if (sortOrder === 'asc') {
                return a[sortBy] - b[sortBy];
            } else {
                return b[sortBy] - a[sortBy];
            }
        });
        return sortedData;
    };

    const filterData = (dataToFilter) => {
        if (filterBy === 'all') {
            return dataToFilter;
        }
        return dataToFilter.filter((llm) => llm[filterBy] && llm[filterBy] !== '');
    };

    const sourceFilterData = (dataToFilter) => {
        if (sourceFilter === 'all') {
            return dataToFilter;
        }
        return dataToFilter.filter((llm) => llm.source === sourceFilter);
    };

    const searchFilteredData = (dataToFilter) => {
        if (searchTerm === '') {
            return dataToFilter;
        }
        const lowerCaseSearch = searchTerm.toLowerCase();
        return dataToFilter.filter((llm) => llm.name.toLowerCase().includes(lowerCaseSearch));
    };

    const filteredAndSortedData = sourceFilterData(filterData(sortData(searchFilteredData(llmData))));

    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const baseImagePath = "/llm-medcon/"

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    const images = [
        'http://localhost:3000/llm-medcon/bloom_pubmedqa.svg',
        'http://localhost:3000/llm-medcon/bloom_pubmedqa.svg',
        'http://localhost:3000/llm-medcon/bloom_pubmedqa.svg',
        'http://localhost:3000/llm-medcon/bloom_pubmedqa.svg',
    ];

    return (
        <Container className={'dashboard'}>
            {isViewerOpen && (
                <ImageViewer
                    src={ images }
                    currentIndex={ currentImage }
                    disableScroll={ false }
                    closeOnClickOutside={ true }
                    onClose={ closeImageViewer }
                />
            )}
            <Row>
                <Col className={'my-4 navigator'}>
                    <a href="/">LLM Radar</a>
                    <img src="/breadcrumb.svg" height="10" className="mx-3" alt="Pfeil rot rechts" />
                    <a href="/llm">LLM Map</a>
                </Col>
            </Row>
            <Row>
                <Col className={'heading'}>
                    <h1 className={'text-center my-2'}>Large Language Model Map</h1>
                    <p className={'text-center subtitle'}>- aktuelle LLM in der Übersicht -</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Introduction />
                </Col>
            </Row>
            <MetricAccordion />
            <SumAccordion />
            <Row>
                <h5 className={'mt-3'}>Medical Context</h5>
                <p>Um Sprachmodelle im medizinischen Kontext zu testen, wurde das Tool <a href={'https://github.com/viscom-ulm/LLMMaps'}>LLMMaps</a> mit dem öffentlich verfügbaren Datensatz <a href={'https://pubmedqa.github.io/'}>PubMedQA</a> verwendet. Das Tool visualisiert alle Fragen in feingranularen Kategorien und gibt dann an, wie genau die Fragen in der jeweiligen Unterkategorie beantwortet wurden. Dieser Wert wird proportional visualisiert, abhängig davon, wie die Kategorie mit dem Hintergrund gefüllt ist. Die Grafik konnte nur für die Modelle erstellt werden, die lokal ausführbar waren.</p>
            </Row>
            <Row>
                <Col><h3 className={'mt-4'}>LLM List</h3></Col>
            </Row>
            <Row>
                <Col>
                    <Card className={'px-4 py-3'} style={{backgroundColor: 'lightgray'}}>
                        <Form>
                            <Row className="mb-3">
                                <Col>
                                    <Form.Label>Search by Name:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter name to search"
                                        value={searchTerm}
                                        onChange={handleSearch}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label className="d-flex align-items-center">Sort by:</Form.Label>
                                    <Form.Select value={sortBy} onChange={handleSortByChange}>
                                        <option value="eloRate">Elo Rate</option>
                                        <option value="mtBench">MT-Bench</option>
                                        <option value="mmlu">MMLU</option>
                                        <option value="arc">Al2 Reasoning Challenge</option>
                                        <option value="hs">HellaSwag</option>
                                        <option value="tqa">TruthfulQA</option>
                                    </Form.Select>

                                    <Form.Label className="d-flex align-items-center">Sort order:</Form.Label>
                                    <div className="d-flex">
                                        <Form.Check
                                            className={'mx-2'}
                                            type="checkbox"
                                            name="sortOrder"
                                            label="Ascending"
                                            value="asc"
                                            checked={sortOrder === 'asc'}
                                            onChange={handleSortOrderChange}
                                        />
                                        <Form.Check
                                            className={'mx-2'}
                                            type="checkbox"
                                            name="sortOrder"
                                            label="Descending"
                                            value="desc"
                                            checked={sortOrder === 'desc'}
                                            onChange={handleSortOrderChange}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <Form.Label className="d-flex align-items-center">Filter by (hides models without value for selected field):</Form.Label>
                                    <Form.Select value={filterBy} onChange={handleFilterChange}>
                                        <option value="all">Show All</option>
                                        <option value="description">Description</option>
                                        <option value="publisher">Publisher</option>
                                        <option value="pricing">Pricing</option>
                                        <option value="license">License</option>
                                        <option value="medcon">Medical Context</option>
                                        <option value="sum_reg">Summary Regulatory</option>
                                        <option value="sum_law">Summary Law</option>
                                        <option value="sum_med">Summary Medical</option>
                                    </Form.Select>

                                    <Form.Label className="d-flex align-items-center">License Filter:</Form.Label>
                                    <div className="d-flex">
                                        <Form.Check
                                            className={'mx-2'}
                                            type="checkbox"
                                            name="sourceFilter"
                                            label="All"
                                            value="all"
                                            checked={sourceFilter === 'all'}
                                            onChange={handleSourceFilterChange}
                                        />
                                        <Form.Check
                                            className={'mx-2'}
                                            type="checkbox"
                                            name="sourceFilter"
                                            label="Commercial use"
                                            value="cu"
                                            checked={sourceFilter === 'cu'}
                                            onChange={handleSourceFilterChange}
                                        />
                                        <Form.Check
                                            className={'mx-2'}
                                            type="checkbox"
                                            name="sourceFilter"
                                            label="Limited commercial"
                                            value="lc"
                                            checked={sourceFilter === 'lc'}
                                            onChange={handleSourceFilterChange}
                                        />
                                        <Form.Check
                                            className={'mx-2'}
                                            type="checkbox"
                                            name="sourceFilter"
                                            label="Non-commercial"
                                            value="nc"
                                            checked={sourceFilter === 'nc'}
                                            onChange={handleSourceFilterChange}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>
            <Row>
                {
                    filteredAndSortedData.length > 0 ? (
                        filteredAndSortedData.map((llm, index) => (
                        <Col xs={12} key={index}>
                            <Accordion className={'my-2'}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        <Row>
                                            <Col>
                                                <strong><a href={llm.link} style={{textDecoration: 'None'}} target={'_blank'}  rel="noreferrer" className={"m-2"}>{llm.name}{' '}<BoxArrowUpRight /></a>{' '}</strong>
                                                {llm.eloRate && <><Badge bg="warning" text="dark">EloRate <Badge bg="secondary">{llm.eloRate}</Badge></Badge>{' '}</>}
                                                {llm.mtBench && <><Badge bg="secondary">MTBench <Badge bg="dark">{llm.mtBench}</Badge></Badge>{' '}</>}
                                                {llm.mmlu && <><Badge bg="dark">MMLU <Badge bg="secondary">{llm.mmlu}</Badge></Badge>{' '}</>}
                                                {llm.arc && <><Badge bg="primary">ARC <Badge bg="secondary">{llm.arc}</Badge></Badge>{' '}</>}
                                                {llm.hs && <><Badge bg="info">HellaSwag <Badge bg="secondary">{llm.hs}</Badge></Badge>{' '}</>}
                                                {llm.tqa && <><Badge bg="success">TQA <Badge bg="secondary">{llm.tqa}</Badge></Badge>{' '}</>}
                                            </Col>
                                        </Row>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            {//description
                                                llm.description && llm.description !== "" &&
                                                <Col xs={llmXs} md={llmMd} lg={llmLg} className={llmClass}>
                                                    <p><strong><i>Description</i></strong></p>
                                                    <TruncateText text={llm.description} />
                                                </Col>
                                            }
                                            {//Publisher
                                                llm.publisher && llm.publisher !== "" &&
                                                <Col xs={llmXs} md={llmMd} lg={llmLg} className={llmClass}>
                                                    <p><strong><i>Publisher</i></strong></p>
                                                    <p>{llm.publisher}</p>
                                                </Col>
                                            }
                                            {//Pricing
                                                llm.pricing && llm.pricing !== "" &&
                                                <Col xs={llmXs} md={llmMd} lg={llmLg} className={llmClass}>
                                                    <p><strong><i>Pricing</i></strong></p>
                                                    <div dangerouslySetInnerHTML={{ __html: llm.pricing }} />
                                                </Col>
                                            }
                                            {//license
                                                llm.license && llm.license !== "" &&
                                                <Col xs={llmXs} md={llmMd} lg={llmLg} className={llmClass}>
                                                    <p><strong><i>License</i></strong></p>
                                                    <p>{llm.license}</p>
                                                </Col>
                                            }
                                            {//medical context image
                                                llm.medcon &&
                                                <Col xs={llmXs} md={llmMd} lg={llmLg} className={llmClass}>
                                                    <p><strong><i>Medical Context</i></strong></p>
                                                    <img
                                                        style={{cursor: 'pointer'}}
                                                        src={`${baseImagePath}${llm.medcon}`}
                                                        alt={`Medical Context for ${llm.name}`}
                                                        className={'border'}
                                                        width="250"
                                                        onClick={ () => openImageViewer(1) }
                                                    />
                                                </Col>
                                            }
                                            {//summarization
                                                ((llm.sum_med && llm.sum_med !== "")
                                                    || (llm.sum_law && llm.sum_law !== "")
                                                    || (llm.sum_reg && llm.sum_reg !== "")
                                                ) &&
                                                <Col xs={llmXs} md={llmMd} lg={llmLg} className={llmClass}>
                                                    <p><strong><i>Sumarization</i></strong></p>
                                                    <Accordion>
                                                        { llm.sum_reg && llm.sum_reg !== "" &&
                                                            <Accordion.Item eventKey="0">
                                                                <Accordion.Header>Regulatory Context</Accordion.Header>
                                                                <Accordion.Body><div dangerouslySetInnerHTML={{ __html: llm.sum_reg }} /></Accordion.Body>
                                                            </Accordion.Item>
                                                        }
                                                        { llm.sum_law && llm.sum_law !== "" &&
                                                            <Accordion.Item eventKey="1">
                                                                <Accordion.Header>Law Context</Accordion.Header>
                                                                <Accordion.Body><div dangerouslySetInnerHTML={{ __html: llm.sum_law }} /></Accordion.Body>
                                                            </Accordion.Item>
                                                        }
                                                        { llm.sum_med && llm.sum_med !== "" &&
                                                            <Accordion.Item eventKey="2">
                                                                <Accordion.Header>Medical Context</Accordion.Header>
                                                                <Accordion.Body><div dangerouslySetInnerHTML={{ __html: llm.sum_med }} /></Accordion.Body>
                                                            </Accordion.Item>
                                                        }
                                                    </Accordion>
                                                </Col>
                                            }
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>))
                    ) : (
                        <Col>
                            <Card className={'my-2'} bg={'warning'}>
                                <Card.Body>
                                    Für die aktuellen Filtereinstellungen wurde kein Eintrag gefunden
                                </Card.Body>
                            </Card>
                        </Col>
                    )
                }
            </Row>
            <Row>
                <Col>
                    <Sources />
                </Col>
            </Row>
        </Container>
    );
}

export default LlmView;