// Layout.js
import React from 'react';
import HeaderRadar from './HeaderRadar';
import Footer from './Footer';

const Layout = ({ children }) => {
  return (
    <div>
      <HeaderRadar />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
