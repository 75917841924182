import {Col, Row, Accordion} from "react-bootstrap";
import CardToggle from './CardToggle'
import MetricCollapse from "./MetricCollapse";

function MetricAccordion() {
    return (
        <Accordion>
            <Row>
                <Col><h5 className={'mt-2'}>Benchmarks</h5></Col>
            </Row>
            <Row>
                <Col lg={4}><CardToggle eventKey="0" bg={'warning'}>Arena Elo Rating - (click for details)</CardToggle></Col>
                <Col lg={4}><CardToggle eventKey="1" bg={'secondary'} text={'white'}>MT-Bench - (click for details)</CardToggle></Col>
                <Col lg={4}><CardToggle eventKey="2" bg={'dark'} text={'white'}>MMLU - (click for details)</CardToggle></Col>
            </Row>
            <Row>
                <Col>
                    <Accordion.Collapse eventKey="0">
                        <MetricCollapse>
                            Arena Elo Rating
                            <hr/>
                            <p>
                            Das Arena Elo Rating ist ein Benchmark, der aus den Ergebnisdaten der Chatbot Arena mit Hilfe des Elo Rating Systems gewonnen wird. Zunächst wird die Chatbot Arena kurz beschrieben, bevor das Elo Rating System erläutert wird.
                            </p>
                            <div className={'text-center'}>
                                <Row>
                                    <Col>
                                        <img src={'/metrics/arena-battle.png'} alt={'arena battle'} height={800} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p><i>Abbildung 1: Chatbot Arena [1]</i></p>
                                    </Col>
                                </Row>
                            </div>
                            <p>
                            Die Chatbot Arena ist eine Crowdsourcing-Benchmarking-Plattform. Wie in Abbildung 1 dargestellt, haben die Nutzer die Möglichkeit, mit zwei anonymen Modellen gleichzeitig zu interagieren. Dabei wird beiden Modellen die gleiche Frage gestellt. Anschließend wählen die Benutzer das bevorzugte Modell, wobei die Identität der Modelle erst nach der Abstimmung bekanntgegeben wird. Nach einem Monat Betrieb von Chatbot Arena wurden bereits rund 30.000 Stimmen abgegeben. Mittlerweile sind es über 90.000. Diese Plattform nutzt keine vordefinierten Fragen, um eine Vielzahl von unbeschränkten Anwendungsfällen und echten Nutzerstimmen zu erfassen. [2]
                            </p>
                            <p>
                            Basierend auf den Ergebnissen der Chatbot Arena werden mit Hilfe des Elo-Rating-Systems die Kennzahlen erhoben.
                            </p>
                            <p>
                            Das Elo Rating System wurde ursprünglich von Arpad Elo entwickelt, um Schachspieler zu bewerten. Bis heute hat sich das System etabliert und wird immer noch von der Welt-Schachföderation (FIDE) angewendet. [2]
                            </p>
                            <p>
                            Inzwischen wurde das System auch für andere Bereiche wie Videospiele, American Football oder Tischtennis adaptiert. Es wurde speziell für Geschicklichkeitsspiele entwickelt, die in der Regel von zwei Spielern gespielt werden. Der Unterschied in der Bewertung zwischen den Spielern dient als Vorhersage für das Ergebnis eines einzelnen Spiels. Wenn Spieler mit gleichen Bewertungen gegeneinander spielen, sollten sie etwa gleich viele Siege erzielen. Ein Spieler mit einer Bewertung, die um 100 Punkte höher ist als die seines Gegners, hat voraussichtlich eine Gewinnchance von 64%. Bei einem Unterschied von 200 Punkten steigt die erwartete Gewinnchance des stärkeren Spielers auf 76%. [3]
                            </p>
                            <p>
                            Das Bewertungssystem eignet sich gut für den Wettbewerb der KI-Modelle, da es dafür sorgt, dass viele Kandidaten bzw. Modelle gegeneinander antreten und paarweise verglichen werden. [4]
                            </p>
                            <p>
                            Abschließend wird die Berechnung des Elo-Ranglistensystems für zwei Spieler A und B betrachtet.
                            </p>
                            <p>
                            Wenn Spieler A eine Wertung von RA und Spieler B eine Wertung von RB hat, kann die erwartete Punktzahl von Spieler A mit folgender Formel berechnet werden: [3]
                            </p>
                            <p>
                            EA = 1 / (1 + 10(RB - RA) / 400))
                            </p>
                            <p>
                            Für jeden 400-Punkte-Vorsprung gegenüber der Bewertung des gegnerischen Spielers ist die erwartete Bewertung 10-mal höher als die des Gegners. Diese Konstante hat keine Auswirkungen auf die zugrunde liegenden Prinzipien und wurde aus historischen Gründen gewählt. [5]
                            </p>
                            <p>
                            Wenn während eines Turniers die tatsächlichen Punktezahlen eines Spielers die erwartete Bewertung übersteigen, ist dies nach dem ELO-System ein Hinweis darauf, dass die Wertung des Spielers zu niedrig ist und angepasst werden muss, entweder nach oben oder nach unten. Eine Aktualisierung der Wertung erfolgt ebenfalls gemäß der Idee von Arpad Elo durch eine lineare Anpassung, die proportional zur Abweichung der Spieler von ihrer erwarteten Punktzahl ist. Die entsprechende Formel lautet wie folgt: [3]
                            </p>
                            <p>
                            R’A = RA + K * (SA - EA)
                            </p>
                            <br/>
                            <hr/>
                            <p>
                                [1] LMSYS
                                2023  Chat with Open Large Language Models. Electronic document,
                                https://chat.lmsys.org/?arena, accessed October 17. 2023.
                            </p>
                            <p>
                                [2] Zheng, Lianmin, Wei-Lin Chiang, Ying Sheng, Siyuan Zhuang, Zhanghao Wu, Yonghao Zhuang, Zi Lin, Zhuohan Li, Dacheng Li, Eric P. Xing, Hao Zhang, Joseph E. Gonzalez, and Ion Stoica;
                                2023c;  Judging LLM-as-a-judge with MT-Bench and Chatbot Arena;
                                https://arxiv.org/pdf/2306.05685.pdf.

                            </p>
                            <p>[3] Edelkamp, Stefan; 2021  ELO System for Skat and Other Games of Chance;
                                https://arxiv.org/pdf/2104.05422.pdf; accessed October 12. 2023
                            </p>
                            <p>[4] 2023b  Chatbot Arena: Benchmarking LLMs in the Wild with Elo Ratings | LMSYS Org. Electronic document;
                                https://lmsys.org/blog/2023-05-03-arena/; accessed October 12. 2023.
                            </p>
                            <p>[5] Pelánek, Radek
                                2016  Applications of the Elo rating system in adaptive educational systems. Computers & Education; 98:169–179;
                                https://www.fi.muni.cz/~xpelanek/publications/CAE-elo.pdf; accessed October 13. 2023.
                            </p>
                        </MetricCollapse>
                    </Accordion.Collapse>
                    <Accordion.Collapse eventKey="1">
                        <MetricCollapse>
                            MT-Bench
                            <hr/>
                            <p>MT-Bench ist ein neuer Benchmark der Large Model Systems Organization (LMSYS). Ziel dieses Benchmarks ist es, menschliche Präferenzen von LLMs besser zu evaluieren, da etablierte Metriken wie MMLU (s.u.), HellaSwag (s.u.) und HumanEval aus Sicht der LMSYS unzureichend sind. Ein Grund dafür ist, dass die genannten Metriken oft LLMs anhand geschlossener Fragen mit prägnanten Antworten testen, was nicht die typischen Anwendungsfälle von LLM-basierten Chat-Assistenten widerspiegelt. [1]</p>
                            <p>MT-Bench ist eine sorgfältig zusammengestellte Sammlung von 80 mehrteiligen Fragen, die entwickelt wurden, um die Fähigkeiten von Modellen in Multi-Turn-Gesprächen und beim Befolgen von Anweisungen zu evaluieren. Diese Sammlung wird empfohlen, da sie eine hochwertige Ergänzung zur Evaluierung von Chatbots und ähnlichen Modellen darstellt und sicherstellt, dass die Modelle in komplexen Gesprächssituationen und bei der Ausführung von Anweisungen erfolgreich agieren können. [1]</p>
                            <div className={'text-center'}>
                                <Row>
                                    <Col>
                                        <img src={'/metrics/mt-bench.png'} alt={'Beispielfragen von MT-Bench '} height={200} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p><i>Abbildung 1: Beispielfragen von MT-Bench [1]</i></p>
                                    </Col>
                                </Row>
                            </div>
                            <p>Abbildung 1 zeigt drei Beispiele von genutzten Beispielfragen, die jeweils zweistufige Turns aufweisen und nach Kategorien eingeteilt sind.</p>
                            <p>Das Prinzip von MT-Bench besteht darin, leistungsfähige LLMs wie GPT-4 als Richter zu verwenden, um die Antworten verschiedener Modelle im Test zu validieren. Die Forscher, welche das dazugehörige Paper "Judging LLM-as-a-judge" veröffentlichten, haben zunächst mögliche Einschränkungen von LLM-Richtern erörtert. [1]</p>
                            <ul>
                                <li>Präferenz für Position: Bevorzugung der ersten Antwort in einem Paarvergleich</li>
                                <li>Neigung zu langen Antworten: Vorliebe für längere Antworten, unabhängig von ihrer Qualität</li>
                                <li>Selbstzentriertheit: Tendenz zur Bevorzugung eigener Antworten</li>
                                <li>Eingeschränkte Fähigkeit zur Problemlösung: Schwierigkeiten bei der Bewertung mathematischer und logischer Fragen</li>
                            </ul>
                            <p>Das Paper untersucht verschiedene Ansätze, um diese Einschränkungen zu minimieren. Dazu gehören der Few-Shot-Richter, Chain-of-Thought-Richter, Referenz-basierte Richter und Fine-Tuned Richter. Eine detaillierte Betrachtung dieser Ansätze erfolgt an dieser Stelle nicht. Durch die Implementierung einiger dieser Lösungen wurde festgestellt, dass trotz der Einschränkungen starke LLM-Richter wie GPT-4 eine beeindruckende Übereinstimmung mit sowohl kontrollierten als auch von einer breiten Masse generierten menschlichen Präferenzen zu entsprechen. Dabei wird eine Übereinstimmung von über 80% erzielt, welche der Übereinstimmung zwischen zwei verschiedenen menschlichen Richtern entspricht. Daher kann LLM als Richter, wenn sorgfältig verwendet, als eine skalierbare und erklärliche Annäherung an menschliche Präferenzen dienen. [1]</p>
                            <p>Der MT-Bench-Wert bewertet die Qualität der Antworten der Modelle, indem er misst, inwieweit die Modellantworten von Chat-Assistenten den Bewertungen von GPT-4 entsprechen. Ein hoher MT-Bench-Wert deutet auf eine starke Übereinstimmung zwischen den Bewertungen von GPT-4 und den Modellantworten hin. Die Skala geht von 1-10.</p>
                            <br/>
                            <hr/>
                            <p>
                                [1] Zheng, Lianmin et al.;
                                2023a;  Chatbot Arena Leaderboard Week 8: Introducing MT-Bench and Vicuna-33B | LMSYS Org. Electronic document;
                                https://lmsys.org/blog/2023-06-22-leaderboard/; accessed October 13. 2023.
                            </p>
                        </MetricCollapse>
                    </Accordion.Collapse>
                    <Accordion.Collapse eventKey="2">
                        <MetricCollapse>
                            MMLU
                            <hr/>
                            <p>Der Benchmark „Measuring Massive Multitask Language Understanding“, kurz MMLU, dient der Messung der Multitasking-Genauigkeit von Textmodellen. Er wurde von einer Gruppe von Forschern entwickelt und 2021 veröffentlicht, um die Lücke zwischen den umfangreichen Erkenntnissen, die während des Pre-Trainings von Modellen gewonnen werden, und den bestehenden Erfolgsmessungen zu schließen. [1]</p>
                            <p>Der Benchmark deckt mit insgesamt 15908 Multiple-Choice-Fragen ein breites Spektrum an Wissensgebieten ab, darunter Geistes- und Sozialwissenschaften, Naturwissenschaften und andere Bereiche. Die Fragen wurden manuell von Studenten und Absolventen aus frei zugänglichen Online-Quellen zusammengestellt, darunter bekannte Prüfungen wie Graduate Record Examination und United States Medical Licensing Examination. Der Schwierigkeitsgrad variiert zwischen Grundschulniveau und professionellem Niveau. [1]</p>
                            <div className={'text-center'}>
                                <Row>
                                    <Col>
                                        <img src={'/metrics/mmlu.png'} alt={'MMLU Beispiel Fragen'} height={250} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p><i>Abbildung 1: MMLU Beispiel Fragen [1]</i></p>
                                    </Col>
                                </Row>
                            </div>
                            <p>Abbildung 1 zeigt zwei Beispielfragen aus dem Datensatz, eine physikalische und eine mathematische Frage. Eine unspezialisierte Testgruppe erreichte eine durchschnittliche Trefferquote von 34,5%. Auf Expertenebene kann die Genauigkeit deutlich höher sein, z. B. wurden bei der Medical Licensing Examination bei den besten 5% der Teilnehmer Genauigkeiten von bis zu 87% festgestellt. [1]</p>
                            <p>Die Modelle werden auf Basis von "Zero-Shot" und "Few-Shot (5)" getestet. "Few-Shot (5)" bedeutet, dass 5 Fragen und ihre korrekten Antworten als Beispiele im Prompt enthalten sind, bevor die eigentliche Frage gestellt wird. "Zero-Shot" bezieht sich auf die Frage ohne Beispiele. [1]</p>
                            <p>Die Leistung der Modelle wird dann anhand der Genauigkeit der Antworten auf die Fragen gemessen und als prozentualer Anteil der richtigen Antworten berechnet. [1]</p>
                            <br/>
                            <hr/>
                            <p>[1] Hendrycks, Dan, Collin Burns, Steven Basart, Andy Zou, Mantas Mazeika, Dawn Song, and Jacob Steinhardt;
                                2020; Measuring Massive Multitask Language Understanding;
                                https://arxiv.org/pdf/2009.03300.pdf; accessed October 13. 2023
                            </p>
                        </MetricCollapse>
                    </Accordion.Collapse>
                </Col>
            </Row>
            <Row className={'mt-3'}>
                <Col lg={4}><CardToggle eventKey="3" bg={'primary'} text={'white'}>Al2 Reasoning Challenge - (click for details)</CardToggle></Col>
                <Col lg={4}><CardToggle eventKey="4" bg={'info'}>HellaSwag - (click for details)</CardToggle></Col>
                <Col lg={4}><CardToggle eventKey="5" bg={'success'} text={'white'}>TruthfulQA - (click for details)</CardToggle></Col>
            </Row>
            <Row>
                <Col>
                    <Accordion.Collapse eventKey="3">
                        <MetricCollapse>
                            Al2 Reasoning Challenge
                            <hr/>
                            <p>Die AI2 Reasoning Challenge (ARC) ist eine umfassende Forschungsinitiative, die ins Leben gerufen wurde, um das Gebiet des Frage-Antwort-Systems (QA) voranzutreiben und die Forschung im Bereich komplexer Denkaufgaben zu fördern. ARC besteht aus einem neuen Fragenkatalog, einem Textkorpus und Basismodellen. [1]</p>
                            <p>Die Grundlage bildet der ARC-Datensatz, der 7.787 Fragen aus dem Bereich der Naturwissenschaften enthält, wobei jede Frage eine Multiple-Choice-Frage ist und aus verschiedenen Quellen stammt. Diese Fragen sind in zwei Gruppen unterteilt: ein „Challenge Set“ und ein „Easy Set“. Das Challenge-Set besteht aus 2.590 besonders anspruchsvollen Fragen, während das Easy-Set 5.197 leichtere Fragen enthält. Im Gegensatz zu vielen bestehenden Datensätzen, die sich hauptsächlich auf faktenbasierte Fragen konzentrieren, bei denen die Antworten durch oberflächliche Hinweise gefunden werden können, führt ARC eine anspruchsvolle Sammlung von Fragen aus dem Bereich der Naturwissenschaften ein. Diese Fragen erfordern oft tiefgründiges Denken und die Fähigkeit, mehrere Informationen miteinander zu verknüpfen, um zu einer Antwort zu gelangen. [1]</p>
                            <div className={'text-center'}>
                                <Row>
                                    <Col>
                                        <img src={'/metrics/arc.png'} alt={'ARC Beispielfragen'} height={400} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p><i>Abbildung 1: ARC Beispielfragen [1]</i></p>
                                    </Col>
                                </Row>
                            </div>
                            <p>Abbildung 1 zeigt beispielhaft einige Fragen aus verschiedenen Kategorien des Datensatzes.</p>
                            <p>ARC bietet auch eine wertvolle Ressource namens mit dem ARC Textkorpus an, eine umfangreiche Sammlung von naturwissenschaftlichen Sätzen, die aus dem Web extrahiert wurden. Dieses Korpus enthält 14 Millionen Sätze und dient als nützlicher Ausgangspunkt für die Bewältigung der Herausforderungen, die im ARC Challenge gestellt werden. [1]</p>
                            <p>Die verschiedenen Modelle werden mit dem ARC-Datensatz getestet und der Wert gibt schließlich die prozentuale Genauigkeit der richtig beantworteten Fragen an.</p>
                            <br/>
                            <hr/>
                            <p>
                                [1] Clark, Peter, Isaac Cowhey, Oren Etzioni, Tushar Khot, Ashish Sabharwal, Carissa Schoenick, and Oyvind Tafjord;
                                2018;  Think you have Solved Question Answering? Try ARC, the AI2 Reasoning Challenge;
                                https://arxiv.org/pdf/1803.05457.pdf
                            </p>
                        </MetricCollapse>
                    </Accordion.Collapse>
                    <Accordion.Collapse eventKey="4">
                        <MetricCollapse>
                            HellaSwag
                            <hr/>
                            <p>HellaSwag ist ein herausforderndes Datenset zur Verbesserung des Alltagslogik-Verständnisses und der Kontextbewertung. Es baut auf dem Erfolg des SWAG-Datensets auf, dessen Ziel es ist, die korrekte Fortsetzung einer Situation auszuwählen. HellaSwag hebt sich jedoch von SWAG ab, indem es gezielt schwierigere Beispiele und echte Herausforderungen für hochentwickelte NLP-Modelle bietet. [1]</p>
                            <div className={'text-center'}>
                                <Row>
                                    <Col>
                                        <img src={'/metrics/hellaswag.png'} alt={'HellaSwag Beispielfragen'} height={400} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p><i>Abbildung 1: HellaSwag Beispielfragen [1]</i></p>
                                    </Col>
                                </Row>
                            </div>
                            <p>Abbildung 1 zeit 2 Beispielfragen, indem in beiden Fällen eine Textgenerierung getestet wird. Dazu wird ein Kontext geschaffen und das Modell soll sich für eine der Antwortmöglichkeiten als plausibelste Fortsetzung entscheiden.</p>
                            <p>HellaSwag ist somit eine herausfordernde Benchmark für NLP-Modelle und die aktuellen Fortschritte in der Textverarbeitung herausfordert. Es ist ein Datenset, das den Fokus auf das Erkennen von Textverfälschungen und das Verstehen von Kontext betont. Aus der dazu gehörigen Studie wird deutlich, dass auch leistungsstarke Modelle wie BERT noch Schwierigkeiten haben, die korrekte textuelle Fortsetzung zu liefern. [1]</p>
                            <p>Der "HellaSwag-Score" gibt die Leistung oder Genauigkeit eines Modells auf dem HellaSwag-Datensatz wieder und wird in Prozent ausgedrückt.</p>
                            <br/>
                            <hr/>
                            <p>
                                [1] Zellers, Rowan, Ari Holtzman, Yonatan Bisk, Ali Farhadi, and Yejin Choi;
                                2019;  HellaSwag: Can a Machine Really Finish Your Sentence?;
                                https://arxiv.org/pdf/1905.07830.pdf.
                            </p>
                        </MetricCollapse>
                    </Accordion.Collapse>
                    <Accordion.Collapse eventKey="5">
                        <MetricCollapse>
                            TruthfulQA
                            <hr/>
                            <p>TruthfulQA ist ein Benchmark, um zu messen, ob ein Sprachmodell bei der Generierung von Antworten auf Fragen wahrheitsgetreu ist. Der Benchmark umfasst 817 Fragen aus 38 Kategorien, darunter Gesundheit, Recht, Finanzen und Politik. Es geht dabei darum Antworten auf Fragen zu generieren, insbesondere in Fällen, in denen Menschen aufgrund von Missverständnissen falsche Antworten geben könnten. [1]</p>
                            <div className={'text-center'}>
                                <Row>
                                    <Col>
                                        <img src={'/metrics/tqa.png'} alt={'Beispielfragen TruthfulQA'} height={400} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p><i>Abbildung 1: Beispielfragen TruthfulQA [1]</i></p>
                                    </Col>
                                </Row>
                            </div>
                            <p>Abbildung 1 präsentiert einige Beispielfragen aus dem TruthfulQA-Fragenkatalog mit den dazu gehörigen Antworten von GPT3. Wie deutlich wird, ist es für NLP-Modelle eine Herausforderung, gute Ergebnisse in diesem Bereich zu erzielen, wie anhand der fehlerhaften Antworten erkennbar ist. [1]</p>
                            <p>Dennoch hat die begleitende Studie gezeigt, dass Sprachmodelle durchaus in der Lage sind, präzise und wahrheitsgetreue Antworten auf Fragen zu generieren. Allerdings haben sie das Potenzial, Menschen mit falschen Antworten zu verwirren, indem sie verbreitete Missverständnisse wiedergeben. Die Studie legt nahe, dass dies darauf zurückzuführen ist, dass Sprachmodelle oft auf großen Textmengen aus dem Web trainiert werden, die falsche oder irreführende Informationen enthalten können. [1]</p>
                            <p>Um die Leistung von Sprachmodellen bei der Generierung wahrheitsgemäßer Antworten zu verbessern, werden mehrere Empfehlungen in der Studie vorgestellt. Dazu gehört das Feintuning von Sprachmodellen mit Trainingszielen, die sich nicht auf die Nachahmung von Texten aus dem Web beschränken, beispielsweise die Verwendung von Faktenüberprüfungsdatensätzen oder die Einbeziehung externer Wissensquellen. Die Studie schlägt auch die Verwendung von menschlichem Feedback vor, um Sprachmodelle darauf zu trainieren, genauere und wahrheitsgemäße Antworten zu generieren. [1]</p>
                            <p>Der genannte Benchmark ist somit ein Wert für die Genauigkeit auf den Frage-Antwort-Paaren des Datensatzes.</p>
                            <br/>
                            <hr/>
                            <p>
                                [1] Lin, Stephanie, Jacob Hilton, and Owain Evans;
                                2021;  TruthfulQA: Measuring How Models Mimic Human Falsehoods;
                                https://arxiv.org/pdf/2109.07958.pdf
                            </p>
                        </MetricCollapse>
                    </Accordion.Collapse>
                </Col>
            </Row>
        </Accordion>
    )
}

export default MetricAccordion;