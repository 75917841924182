import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Accordion, Badge, Button, Card, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { Check, X, ArrowClockwise } from 'react-bootstrap-icons';

function ChapterCard({ chapter, fileAssistants, allChapters, chapterText, setChapterText, chapterDone, setChapterDone, chapterMergedText, setChapterMergedText, baseUrl }) {
  const [accordionActiveKey, setAccordionActiveKey] = useState(0);
  const [counter, setCounter] = useState(0);
  const [accordionStates, setAccordionStates] = useState({});
  const [isMergedTextLoading, setIsMergedTextLoading] = useState(false);
  
  useEffect(() => {
    if (counter === fileAssistants.length) {
      console.log("now trigger merging");
      triggerMerging(chapter.id);
    }
  }, [counter]);
  
  
  const triggerMultipleSum = async (chapterId) => {
    setCounter(0)
    const chapter = allChapters.find(chapter => chapter.id === chapterId);
    fileAssistants.map(async (fileAssistant, index) => {
      try {
        setAccordionStates(prevState => ({
          ...prevState,
          [fileAssistant.assistantId]: {
            isCallState: 1
          }
        }));

        const response = await axios.post(baseUrl + '/chapter_summary', {
          "assistant_id": fileAssistant.assistantId,
          "thread_id": fileAssistant.threadId,
          "chapter": chapter.text
        });

        console.log(fileAssistant.assistantId)
        console.log(accordionStates)
        setAccordionStates(prevState => ({
          ...prevState,
          [fileAssistant.assistantId]: {
            textData: response.data,
            isCallState: 2
          }
        }));

        setCounter(prevCounter => prevCounter + 1);

      } catch (error) {
        console.error('Error in triggerMultipleSum:', error);
        setAccordionStates(prevState => ({
          ...prevState,
          [fileAssistant.assistantId]: {
            isCallState: -1
          }
        }));
      }
    })
  };

  const triggerSingleSum = async (chapterId, assistantId) => {
    const item = fileAssistants.find(fa => fa.assistantId === assistantId)
    const chapter = allChapters.find(chapter => chapter.id === chapterId);
    try {
      setAccordionStates(prevState => ({
        ...prevState,
        [item.assistantId]: {
          isCallState: 1
        }
      }));

      const response = await axios.post(baseUrl + '/chapter_summary', {
        "assistant_id": item.assistantId,
        "thread_id": item.threadId,
        "chapter": chapter.text
      });

      console.log(item.assistantId)
      console.log(accordionStates)
      setAccordionStates(prevState => ({
        ...prevState,
        [item.assistantId]: {
          textData: response.data,
          isCallState: 2
        }
      }));
    } catch (error) {
      console.error('Error creating summary:', error);
      setAccordionStates(prevState => ({
        ...prevState,
        [item.assistantId]: {
          isCallState: -1
        }
      }));
    }
  };

  const triggerMerging = async (chapterId) => {
    setIsMergedTextLoading(true)
    let chapter_texts = '';

    Object.entries(accordionStates).forEach(([assistantId, data], index) => {
        // Append the chapter text to the big string
        chapter_texts += `Chapter text ${index + 1}:\n\n${data.textData}\n\n`;
    });

    const chapter = allChapters.find(chapter => chapter.id === chapterId);
    try {
      const response = await axios.post(baseUrl + '/chapter_merge', {
        "chapter_title": chapter.text,
        "chapter_texts": chapter_texts
      });

      console.log(response)
      setChapterMergedText(prevState => ({
        ...prevState,
        [chapter.id]: response.data
      }));
      setIsMergedTextLoading(false)

      let newChapterTextArray = [...chapterText];
      newChapterTextArray[chapterId] = response.data;
      setChapterText(newChapterTextArray);

    } catch (error) {
      console.error('Error creating merge:', error);
      setIsMergedTextLoading(false)
    }
  };

  const handleTextareaChange = (event, assistantId) => {
    const { value } = event.target;
    setAccordionStates(prevState => ({
      ...prevState,
      [assistantId]: {
        ...prevState[assistantId],
        textData: value
      }
    }));
  };

  const onChangedMergedText = (text, chapterId) => {
    setChapterMergedText(prevState => ({
      ...prevState,
      [chapter.id]: text
    }));
    let newChapterTextArray = [...chapterText];
    newChapterTextArray[chapterId] = text;
    setChapterText(newChapterTextArray);
  }

  return (
    <Accordion className='my-3'activeKey={accordionActiveKey} onSelect={(newKey) => setAccordionActiveKey(newKey)}>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <h4>
            {chapter.level} {chapter.text}
          </h4>
          {chapterDone[chapter.id] && (
            <Badge pill bg="success" className="ms-2">
              <Check />
            </Badge>
          )}
        </Accordion.Header>
        <Accordion.Body>
          <p><strong><i>Single summaries</i></strong>:</p>
          <Accordion>
            {fileAssistants.map((fileAssistant, index) => (
              <Accordion.Item key={index} eventKey={index}>
                <Accordion.Header>
                  Chapter summary from: {fileAssistant.filename}
                  {accordionStates[fileAssistant.assistantId]?.isCallState === -1 && <>
                      <X animation="border" style={{fontSize: 30, color: "red"}} role="status" aria-hidden="true" />
                      <Button onClick={() => triggerSingleSum(chapter.id, fileAssistant.assistantId)} className='py-0 ms-2' variant='outline-dark'>
                        <ArrowClockwise />
                      </Button>
                    </>}
                  {accordionStates[fileAssistant.assistantId]?.isCallState === 1 && (
                    <Spinner animation="border" size="sm" role="status" aria-hidden="true" className="ms-3" />
                  )}
                  {accordionStates[fileAssistant.assistantId]?.isCallState === 2 && <>
                    <Badge pill bg="success" className="ms-2">
                      <Check />
                    </Badge>
                    <Button onClick={() => triggerSingleSum(chapter.id, fileAssistant.assistantId)} className='py-0 ms-2' variant='outline-dark'>
                      <ArrowClockwise />
                    </Button>
                    </>}
                </Accordion.Header>
                <Accordion.Body>
                  <Form.Group controlId={`textInput-${index}`}>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      placeholder="This is where the generated chapter summary comes in."
                      value={accordionStates[fileAssistant.assistantId]?.textData || ''}
                      onChange={(e) => handleTextareaChange(e, fileAssistant.assistantId)}
                    />
                  </Form.Group>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
          <Button onClick={() => triggerMultipleSum(chapter.id)} className='mt-2'>Generate summaries</Button>
          <Form.Group controlId="mergedSummary">
            <Form.Label className='mt-3'><strong><i>Merged summary</i></strong>:
            </Form.Label>
            { !isMergedTextLoading && 
            <Form.Control
              as="textarea"
              rows={5}
              placeholder="This is where the merged text comes in."
              value={chapterMergedText[chapter.id] || ''}
              onChange={(elem) => onChangedMergedText(elem.target.value, chapter.id)}
            />
            }
            { isMergedTextLoading && 
            <Card>
              <Card.Body className='text-center my-5'>
                <Spinner animation="border" size="sm" role="status" aria-hidden="true" />
              </Card.Body>
            </Card>
            }
            &nbsp;(Resize the input by dragging the bottom-right corner)
          </Form.Group>
          <Button onClick={() => triggerMerging(chapter.id)} className='mt-2'>Trigger merge</Button>
          <div className='d-flex justify-content-center'>
            {!chapterDone[chapter.id] && <Button className='m-3' variant='success' onClick={() => {
              setChapterDone(prevState => ({
                ...prevState,
                [chapter.id]: true
              }));
              setAccordionActiveKey(null);
            }}>Mark as done</Button>}
            {chapterDone[chapter.id] && <Button className='m-3' variant='danger' onClick={() => {
              setChapterDone(prevState => ({
                ...prevState,
                [chapter.id]: false
              }));
            }}>Unmark</Button>}
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}


function collectChapters(chapters, collectedChapters = []) {
  chapters.forEach(chapter => {
    collectedChapters.push(chapter);
    if (chapter.children && chapter.children.length > 0) {
      collectChapters(chapter.children, collectedChapters);
    }
  });
  return collectedChapters;
}

function renderChapters(chapters, fileAssistants, chapterText, setChapterText, chapterDone, setChapterDone, chapterMergedText, setChapterMergedText, baseUrl) {
  if (!Array.isArray(chapters)) {
    return null; // Or handle the case when chapters is not an array
  }
  if (chapters.length === 0) {
    return <p className='text-center'>Go back to step 1 and configure chapters</p>
  }

  // Collect all chapters into a single array
  const allChapters = collectChapters(chapters);
  // Sort chapters by indentation in descending order
  const sortedChapters = [...allChapters].sort((a, b) => b.indentation - a.indentation);

  return (
    <div>
      {sortedChapters.map(chapter => (
        <div key={chapter.id}>
          <ChapterCard chapter={chapter} fileAssistants={fileAssistants} allChapters={allChapters} chapterText={chapterText} 
            setChapterText={setChapterText} chapterDone={chapterDone} setChapterDone={setChapterDone} chapterMergedText={chapterMergedText} 
            setChapterMergedText={setChapterMergedText} baseUrl={baseUrl} />
        </div>
      ))}
    </div>
  );
}

function renderOutput(chapters, fileAssistants, chapterText) {
  if (!Array.isArray(chapters)) {
    return null; // Or handle the case when chapters is not an array
  }
  if (chapters.length === 0) {
    return <p className='text-center'>Go back to step 1 and configure chapters</p>
  }

  // Collect all chapters into a single array
  const allChapters = collectChapters(chapters);

  return (
    <div>
      {allChapters.map((chapter, index) => (
          <div key={chapter.id}>
            <p style={{ fontSize: `${26 - 3 * chapter.indentation}px`, fontWeight: 500 }} className='mb-2'>{chapter.level} {chapter.text}</p>
            <div className='mb-2'>
              {chapterText[chapter.id] ? (
                chapterText[chapter.id].split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    <span>{line}</span>
                    <br />
                  </React.Fragment>
                ))
              ) : (
                <span>Generate or modfiy the chapter text on the left side. Once it is done it will be displayed here.</span>
              )}
            </div>
          </div>
        ))}
    </div>
  );
}

function CER({ fileAssistants, parsedJson, title, setTitle, chapterText, setChapterText, chapterDone, setChapterDone, chapterMergedText, setChapterMergedText, baseUrl }) {

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <h3 className='text-center'>Chapters</h3>
            {renderChapters(parsedJson, fileAssistants, chapterText, setChapterText, chapterDone, setChapterDone, chapterMergedText, setChapterMergedText, baseUrl)}
          </Col>
          <Col>
            <h3 className='text-center'>Output preview</h3>
            <Card className='mt-3 p-3'>
            <Form.Group>
              <Form.Control type="text" value={title} placeholder='Title' style={{fontSize: 25, color: "#dc1a22"}} onChange={(event) => setTitle(event.target.value)} />
            </Form.Group>
            {renderOutput(parsedJson, fileAssistants, chapterText)}
            </Card>
          </Col>
        </Row>
      </Container>


    </>
  );
}

export default CER;
