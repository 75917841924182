import React, { useEffect } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';

function Chapter({ onParsedJson, documentText, setDocumentText, documentStructure, setDocumentStructure, fillExampleChapters }) {
 
  const handleInputChange = (event) => {
    setDocumentText(event.target.value);
  };

  useEffect(() => {
    const lines = documentText.split('\n');
    const structure = parseDocumentStructure(lines);
    setDocumentStructure(structure);
    onParsedJson(structure);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentText, setDocumentStructure]);

  const parseDocumentStructure = (lines) => {
    const structure = [];
    const stack = []; // Stack to keep track of parent headings
    let id = 1;
  
    // Recursive function to populate child ids
    const populateChildIds = (parent) => {
      const childIds = []; // Declare childIds array for each parent
      parent.children.forEach((child) => {
        childIds.push(child.id); // Push child id to the childIds array
        if (child.children.length > 0) {
          const nestedChildIds = populateChildIds(child); // Recursively populate child ids
          childIds.push(...nestedChildIds); // Concatenate nested child ids to the childIds array
        }
      });
      parent.child_ids = childIds; // Assign the childIds array to the parent
      return childIds; // Return the childIds array for concatenating in the parent level
    };
  
    lines.forEach((line) => {
      const match = line.match(/^(\s*)(\d+(\.\d+)*)\s+(.*)$/);
      if (match) {
        const indentation = match[1].length / 2; // Convert spaces to indentation level
        const level = match[2];
        const text = match[4].trim();
        const item = { id: id, level: level, text: text, indentation: indentation, child_ids: [], children: [] };
        id += 1;
  
        // If it's a top-level heading or a subheading, find its parent
        let parent = null;
        while (stack.length > 0 && stack[stack.length - 1].indentation >= indentation) {
          stack.pop(); // Pop elements from the stack until finding the appropriate parent
        }
        if (stack.length > 0) {
          parent = stack[stack.length - 1];
        }
  
        if (!parent) {
          // If there's no parent, it's a top-level heading
          structure.push(item);
        } else {
          // Add the item as a child of its parent
          parent.children.push(item);
        }
  
        // Push the item onto the stack for tracking
        stack.push(item);
      }
    });
  
    // Populate child_ids for each parent
    structure.forEach((parent) => {
      populateChildIds(parent); // Call the recursive function to populate child ids
    });
  
    return structure;
  };
  
  
  

  return (
    <>
      <Card className='p-3'>
        <Container>
          <Row>
            <Col>
              <h3>Chapter overview</h3>
                <Form.Group controlId="documentTextArea">
                  <Form.Label>Enter Chapters (intend for subcategories and use chapter numbers):</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={10}
                    value={documentText}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Button onClick={fillExampleChapters}   style={{ background: 'none', border: 'none', color: 'blue', textDecoration: 'underline', cursor: 'pointer', padding: 0 }}>Fill in example structure</Button>
            </Col>
            <Col>    
              <h3>Parsed JSON</h3>
              <pre>{JSON.stringify(documentStructure, null, 2)}</pre>
            </Col>
          </Row>
        </Container>
      </Card>
    </>
  );
}

export default Chapter;
