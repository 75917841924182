import React from "react";

function Introduction() {
    return (
        <>
            <h3 className={'mt-4'}>Introduction</h3>
            <p>Eine vollständige Liste aller Large Language Models zu finden ist keine einfache Aufgabe, da regelmäßig neue LLMs veröffentlicht werden und somit eine umfassende Übersicht schnell veraltet. Bei der Suche nach aktuellen Veröffentlichungen [1, 2, 3], Blogbeiträgen [4, 5] oder Social-Media-Posts [6] werden derzeit zwei Quellen häufig referenziert: das "<a href={'https://huggingface.co/spaces/HuggingFaceH4/open_llm_leaderboard'}>Open LLM Leaderboard</a>" [7] und das "<a href={'https://huggingface.co/spaces/lmsys/chatbot-arena-leaderboard'}>Chatbot Arena Leaderboard</a>" [8]. Beide Quellen enthalten Listen der aktuellen besten LLM-Modelle, die anhand mehrerer Benchmarks verglichen wurden. Die Ranglisten werden kontinuierlich aktualisiert und sind auf der Plattform HuggingFace für jeden frei zugänglich. Unter Berücksichtigung dieser Listen werden auf dieser Seite die relevantesten LLMs mit detaillierten Meta-Informationen vorgestellt und die verwendeten Benchmarks erläutert.</p>
            <p>[Quellen s.u.]</p>
        </>
    )
}

export default Introduction;