// Subpage.js
import React, { useState } from 'react';
import axios from 'axios';
import { Container, Form, Button, Card, Col, Row, Modal, Spinner, Accordion } from 'react-bootstrap';
import { examples } from '../data/scoring-examples';
import { examples_all } from '../data/scoring-examples-all';
import { candidateStrings, stringScores, labelScore, calculateTotalScore } from '../handler/scoringHandler';
import { extract_answer, calculateTokenCostv3 } from '../handler/supportHandler';
import './global.css';
import { createClient } from "@supabase/supabase-js";
import { BoxArrowUpRight } from 'react-bootstrap-icons';

const PmsEvalAlpine = () => {
  const [error, setError] = useState(false);
  const [overlay, setOverlay] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isLoadingJson1, setIsLoadingJson1] = useState(false);
  const [isLoadingJson2, setIsLoadingJson2] = useState(false);
  const [isLoadingJson3, setIsLoadingJson3] = useState(false);
  const [isLoadingFeedback, setIsLoadingFeedback] = useState(false);
  const [cost, setCost] = useState(0);
  const [score, setScore] = useState(0);
  const [rating, setRating] = useState(0);
  const [adapter, setAdapter] = useState(0);
  const [orgScore, setOrgScore] = useState(0);
  const [duration, setDuration] = useState(0);
  const [orgScoreRaw, setOrgScoreRaw] = useState(0);
  const [recommendationState, setRecommendationState] = useState(0);
  const [tags, setTags] = useState('');
  const [text, setText] = useState('');
  const [name, setName] = useState('');
  const [link, setLink] = useState('');
  const [feedback, setFeedback] = useState('');
  const [identifier, setIdentifier] = useState('');
  const [sq1Question, setSq1Question] = useState('');
  const [sq2Question, setSq2Question] = useState('');
  const [sq3Question, setSq3Question] = useState('');
  const [sq1Response, setSq1Response] = useState('');
  const [sq2Response, setSq2Response] = useState('');
  const [sq3Response, setSq3Response] = useState('');
  const [sqScoreLabel, setSqScoreLabel] = useState('');
  const [recommendation, setRecommendation] = useState('');

  const baseUrl = "/api"; //prod
  //const baseUrl = "http://localhost:8000"; //dev

  const supabaseURL = process.env.REACT_APP_SUPABASE_URL;
  const supabasePublicKey = process.env.REACT_APP_SUPABASE_KEY;
  const supabase = createClient(supabaseURL, supabasePublicKey);

  const handleFeedback = async (event) => {
    event.preventDefault();
    setIsLoadingFeedback(true)
 
    const { error } = await supabase
        .from('llmradar_feedback')
        .insert({
          name: name,
          stars: rating,
          feedback: feedback,
          identifier: identifier,
          text: text,
          tags: tags,
          sq1_answer: sq1Response,
          sq2_answer: sq2Response,
          sq3_answer: sq2Response,
          model: "gpt-4-1106-preview",
          adapter: adapter
        })
    console.log(error);
 
    setTimeout(() => {
      setSubmitted(true);
      setName('');
      setRating(0);
      setFeedback('');
      setIsLoadingFeedback(false);
    }, 500);
  }

  const handleValidation = async (aiScore, aiScoreRaw) => {
    const { error } = await supabase
        .from('llmradar_validation')
        .insert({
          identifier: identifier,
          tags: tags,
          text: text,
          org_score: orgScore,
          org_score_raw: orgScoreRaw,
          ai_score: aiScore,
          ai_score_raw: aiScoreRaw,
          model: "gpt-4-1106-preview",
          adapter: adapter
        })
    console.log(error);
  }

  const handleExampleSelect = (exampleDataId) => {
    let exampleData = {}
    if(exampleDataId < 5) {
      exampleData = examples[exampleDataId]
    }else{
      exampleData = examples_all[exampleDataId - 5]
    }

    setTags(exampleData.tags);
    setText(exampleData.text);
    setAdapter(exampleData.adapter);
    setIdentifier(exampleData.identifier);
    setOrgScore(exampleData.score);
    setOrgScoreRaw(exampleData.score_raw);
    setLink(exampleData.link);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowModal(true);
    setSq1Question('');
    setSq2Question('');
    setSq3Question('');
    setSq1Response('');
    setSq2Response('');
    setSq3Response('');
    setDuration(0);
    setScore(0);
    setOverlay(true);
    setError(false);
    setRecommendationState(0);
  };


  const handleModalSubmit = async () => {
    setIsLoading(true);
    setCost(calculateTokenCostv3(text))

    try {
      const response = await axios.post(baseUrl + '/get_scoring_questions', {
        "adapter": adapter
      }, {
        timeout: 500000,
      });

      console.log(response)
      responseHandler(response, 0, response.data.duration)

      setShowModal(false);
      setIsLoadingJson1(true);
      setIsLoadingJson2(true);
      setIsLoadingJson3(true);

    } catch (error) {
      console.error('Error:', error);
      setError(true);
    } finally {
      // Reset loading state
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    if (!isLoading) {
      setShowModal(false);
    }
  };

  const getRecommendation = async (sq1q, sq2q, sq3q, sq1a, sq2a, sq3a, duration) => {
    setRecommendationState(1)
    try {
      const response = await axios.post(baseUrl + '/get_recommodation', {
        "sq1question": sq1q,
        "sq2question": sq2q,
        "sq3question": sq3q,
        "sq1answer": sq1a,
        "sq2answer": sq2a,
        "sq3answer": sq3a
      }, {
        timeout: 500000,
      });

      console.log(response)
      if(response.data && response.data.content) {
        setRecommendationState(2)
        setRecommendation(response.data.content)
      }

      if(response.data.duration) {
        let duration_all = parseFloat(duration) + parseFloat(response.data.duration)
        setDuration(duration_all.toFixed(2))
      }

    } catch (error) {
      console.error('Error:', error);
      setError(true);
    }
  };

  const responseHandler = (response, score, answerDuration) => {
    let counter = 0;
    let sq1q = "";
    let sq2q = "";
    let sq3q = "";
    let sq1a = "";
    let sq2a = "";
    let sq3a = "";

    if(Array.isArray(response.data.questions)) {
      try {
        // Create an array of promises for each request
        response.data.questions.map(async (question, index) => {
          try {
            const result = await axios.post(
              baseUrl + '/answer_question',
              {
                "label": question.label,
                "tags": tags,
                "text": text,
                "alpine": true
              },
              {
                headers: {
                  'Cache-Control': 'no-cache, no-store',
                },
                timeout: 300000,
              }
            );
  
            // Process each result as soon as it comes back
            console.log(`Response for request ${index}:`, result.data);

            let sqResponseTmp = JSON.parse(result.data.content);
            sqResponseTmp.answers = extract_answer(sqResponseTmp)
            sqResponseTmp.answers.sort((a, b) => b.score - a.score);
  
            let answerScore = calculateTotalScore(sqResponseTmp.answers[0].answer, candidateStrings, stringScores);
            score = Number((answerScore + score).toFixed(2))
            setScore(score)
            let labeledScore = labelScore(adapter, score);
            setSqScoreLabel(labeledScore.label);
            let overallDuration = parseFloat(result.data.duration).toFixed(2)
            setDuration(overallDuration);
  
            if (index === 1) {
              setSq1Question(question.text + " (Score: " + answerScore + ")")
              sq1q = question.text + " (Score: " + answerScore + ")"
              setSq1Response(sqResponseTmp);
              sq1a = sqResponseTmp
              setIsLoadingJson1(false);
            } else if (index === 2) {
              setSq2Question(question.text + " (Score: " + answerScore + ")")
              sq2q = question.text + " (Score: " + answerScore + ")"
              setSq2Response(sqResponseTmp);
              sq2a = sqResponseTmp
              setIsLoadingJson2(false);
            } else {
              setSq3Question(question.text + " (Score: " + answerScore + ")")
              sq3q = question.text + " (Score: " + answerScore + ")"
              setSq3Response(sqResponseTmp)
              sq3a = sqResponseTmp
              setIsLoadingJson3(false);
            }

            counter += 1
            if(counter === 3) {
              handleValidation(labeledScore.scoreSmooth, score)
              getRecommendation(sq1q, sq2q, sq3q, sq1a, sq2a, sq3a, overallDuration)
            }

          } catch (error) {
            // Handle different types of errors, including timeouts
            if (axios.isCancel(error)) {
              console.log(`Request ${index} was canceled`);
              loadingOff(index)
            } else if (error.code === 'ECONNABORTED') {
              console.log(`Request ${index} timed out`);
              loadingOff(index)
            } else {
              // Handle other errors
              console.error(`Error for request ${index}:`, error.message);
              loadingOff(index)
            }
          }

        });
  
      } catch (error) {
        // Handle errors
        console.error('Error in responseToJson:', error);
      }
    }

    setOverlay(false)
    setShowModal(false);
  }

  const loadingOff = (index) => {
    if(index === 1) {
      setIsLoadingJson1(false);
    } else if(index === 2) {
      setIsLoadingJson2(false);
    } else {
      setIsLoadingJson3(false);
    }
  }

  return (
    <Container>
      <Row>
        <Col className={'mt-4 navigator'}>
            <a href="/">LLM Radar</a>
            <img src="/breadcrumb.svg" height="10" className="mx-3" alt="Pfeil rot rechts" />
            <a href="/pms1">PMS Showcase</a>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col>
          <h3 className='text-center'>Input</h3>
        </Col>
        <Col className='d-none d-lg-block'>
          <h3 className='text-center'>Output</h3>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <Card className='p-5'>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="exampleDropdown">
                <Form.Label><i>Optionally select an example text/tag pair:</i></Form.Label>
                <Form.Control as="select" onChange={(e) => handleExampleSelect(e.target.value)}>
                  {examples.map((example, index) => (
                    <option key={index} value={index}>
                      {example.label}
                    </option>
                  ))}
                  {examples_all.map((example, index) => (
                    <option key={index + 5} value={index + 5}>
                      {example.label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="tagsInput">
                <Form.Label className='mt-3'><strong><i>Tags</i></strong>:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter tags"
                  value={tags}
                  onChange={(e) => setTags(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="textInput">
                <Form.Label className='mt-3'><strong><i>Text</i></strong>: 
                { link !== '' &&
                  <>
                    {' '}
                    <a href={link} target='_blank' rel="noreferrer">Source link <BoxArrowUpRight /></a>
                  </>
                }
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="Enter text"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                />
                &nbsp;(Resize the input by dragging the bottom-right corner)
              </Form.Group>

              <Form.Group controlId="exampleDropdown2">
                <Form.Label className='mt-3'><strong><i>Select db type</i></strong>:<i> (important for asking the right scoring questions)</i></Form.Label>
                <Form.Control as="select" value={adapter} onChange={(e) => setAdapter(e.target.value)}>
                  <option value={0} default>None</option>
                  {adapter === 1 && <option value={1}>Sicherheitsdatenbank</option>}
                  <option value={2}>Sicherheitsdatenbank</option>
                  <option value={3}>IT-Security Datenbank</option>
                  {adapter === 4 && <option value={4}>Sicherheitsdatenbank</option>}
                  {adapter === 7 && <option value={7}>Sicherheitsdatenbank</option>}
                  <option value={8}>Literaturdatenbank</option>
                  {adapter === 9 && <option value={9}>Sicherheitsdatenbank</option>}
                  <option value={11}>Klinische Prüfung Datenbank</option>
                </Form.Control>
              </Form.Group>

              <div className='text-center mt-3'>
                { (tags === '' || text === '' || adapter === 0) &&
                  <p style={{color: 'darkgoldenrod'}}>Please enter tags, text and choose type before submitting.</p>
                }
                <Button variant="success" type="submit" disabled={tags === '' || text === '' || adapter === 0} title={"sca"}         data-bs-toggle="tooltip" data-bs-placement="top">
                  Try it out!
                </Button>
              </div>
            </Form>
          </Card>
        </Col>
        <Col className='d-lg-none'>
          <h3 className='text-center mt-3'>Output</h3>
        </Col>
        <Col lg={6}>
          <Card className='p-5'>
            <strong><i>Response</i></strong>
            { overlay ? (
              <>
                <br/><br/><br/><br/><br/><br/><br/><br/>
                <p className='text-center' style={{color: "gray"}}>As soon as the answer is available,<br/> it will be displayed here</p>
                <br/><br/><br/><br/><br/><br/><br/><br/><br/>
            </>
            ) : (
              <>
                  {
                    !error ? (  
                      <Accordion className='mt-2'>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            { isLoadingJson1 ? (
                              <>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                {' '}&nbsp;Loading response from openai...
                              </>
                            ) : (
                              <>
                                {sq1Question}
                              </>
                            )
                            }
                          </Accordion.Header>
                          <Accordion.Body>
                            {sq1Response && sq1Response.answers && Array.isArray(sq1Response.answers) ? (
                              <div>
                                {/* Render your response data as needed */}
                                {sq1Response.answers
                                  .map((answerObj, index) => (
                                  <div key={index}>
                                    <p>
                                      <strong>{answerObj.answer}</strong>
                                    </p>
                                    <p>
                                      <i>Erklärung</i>: {answerObj.explanation || answerObj.explaination || answerObj.erklearung || answerObj.erklärung}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            ) : null}
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            { isLoadingJson2 ? (
                              <>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                {' '}&nbsp;Loading response from openai...
                              </>
                            ) : (
                              <>
                                {sq2Question}
                              </>
                            )
                            }
                          </Accordion.Header>
                          <Accordion.Body>
                            {sq2Response && sq2Response.answers && Array.isArray(sq2Response.answers) ? (
                                <div>
                                  {/* Render your response data as needed */}
                                  {sq2Response.answers
                                    .sort((a, b) => b.score - a.score)
                                    .map((answerObj, index) => (
                                    <div key={index}>
                                      <p>
                                        <strong>{answerObj.answer}</strong>
                                      </p>
                                      <p>
                                        <i>Erklärung</i>: {answerObj.explanation || answerObj.explaination || answerObj.erklearung || answerObj.erklärung}
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              ) : null}
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>
                            { isLoadingJson3 ? (
                              <>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                {' '}&nbsp;Loading response from openai...
                              </>
                            ) : (
                              <>
                                {sq3Question}
                              </>
                            )
                            }
                          </Accordion.Header>
                          <Accordion.Body>
                            {sq3Response && sq3Response.answers && Array.isArray(sq3Response.answers) ? (
                                <div>
                                  {/* Render your response data as needed */}
                                  {sq3Response.answers
                                    .sort((a, b) => b.score - a.score)
                                    .map((answerObj, index) => (
                                    <div key={index}>
                                      <p>
                                        <strong>{answerObj.answer}</strong>
                                      </p>
                                      <p>
                                        <i>Erklärung</i>: {answerObj.explanation || answerObj.explaination || answerObj.erklearung || answerObj.erklärung}
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              ) : null}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    ) : (<p className='text-center'>Alert: there was an unexpected error!</p>)
                  }
                  <p className='mt-1 m-0'>&nbsp;(answers sorted descending by confidence)</p>
                    { recommendationState === 0 &&
                      <p className='mt-3 mb-3'>
                        <strong><i>Recommendation</i></strong>:
                        &nbsp;requesting when all scoring questions are loaded.<br/>
                      </p>
                    }
                    { recommendationState === 1 &&
                      <p className='mt-3 mb-3'>
                        <strong><i>Recommendation</i></strong>:
                        &nbsp;<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                        {' '}loading recommendation...<br/>
                        </p>
                    }
                  { recommendationState === 2 &&
                      <>
                      <p className='mt-3 mb-1'><strong><i>Recommendation</i></strong>:</p>
                      <Accordion className='mb-3'>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Collapse here to see the recommendation text</Accordion.Header>
                        <Accordion.Body>{recommendation}</Accordion.Body>
                      </Accordion.Item>
                      </Accordion>
                    </>
                  }
                  <p><strong><i>Overall Score</i></strong>: {score} ({sqScoreLabel})</p>
                  <p><strong><i>Execution Time</i></strong>: {duration}s</p>
                  <p><strong><i>Overall Costs</i></strong> (depends on input length): {cost.toFixed(2)}$</p>
              </>
            )}
          </Card>
        </Col>
      </Row>


      
      <Row className='mt-4'>
        <h3 className='text-center'>Feedback</h3>
        <Col>
          <Card className='p-5'>
            <Form onSubmit={handleFeedback}>
              <Form.Group className="mb-3">
                <Form.Label><i>Name (optionally)</i>:</Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label><i>Rating</i>:</Form.Label>
                <div>
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span
                      key={star}
                      className={`star ${rating >= star ? 'selected' : ''}`}
                      style={{ cursor: "pointer", fontSize: "2.5em" }}
                      onClick={() => {
                        console.log("setted rating")
                        console.log(star)
                        setRating(star)
                      }}
                    >
                      &#9733;
                    </span>
                  ))}
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label><i>Feedback</i>:</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                  required
                />
              </Form.Group>
              <Button type="submit" variant="success">
                { isLoadingFeedback ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    {' '}submitting
                  </>
                ) : (
                  <>
                    Submit Feedback
                  </>
                )
                }
              </Button>
              {submitted && (
                <span className="submitted-text">{' '}&nbsp;&nbsp;Thank you for your feedback!</span>
              )}
            </Form>
          </Card>
        </Col>
      </Row>



      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Attention</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Tthis action costs money. even if it is only a minimal amount, <strong>use it wisely</strong>.
          <br/><br/>
          The response time can currently be <strong>up to 1 minute</strong>.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal} disabled={isLoading}>
            Close
          </Button>
          <Button variant="success" onClick={handleModalSubmit} disabled={isLoading}>
            	{isLoading ? (
                <>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  {' '}Waiting for response...
                </>
              ) : (
                'Submit'
              )}
          </Button>
        </Modal.Footer>
      </Modal>

      
    </Container>
  );
};

export default PmsEvalAlpine;
