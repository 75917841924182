import {Col, Container, Row, Card, ButtonGroup, Button} from "react-bootstrap";
import React from 'react';
import './global.css';
import { CardList } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

function Dashboard() {
    
    return (
        <Container className={'dashboard'}>
            <Row className="mt-4">
                <Col className={'heading'}>
                    <h1 className={'text-center my-2'}>LLm Radar</h1>
                    <p className={'text-center subtitle'}>- Insights from Maximilian Fletschinger's master's thesis -</p>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col className="my-2 d-flex align-items-stretch" sm={12} md={6} lg={4}>
                    <Card className="text-center p-5" as={Link} to={"/llm"} style={{ textDecoration: 'none' }}>
                        <div>
                            <CardList size={40} />
                        </div>
                        <h3 className="mt-3">LLM Map</h3>
                        <p>Overview of current open-source and fee-based LLM models, compared by various metrics</p>
                    </Card>
                </Col>
                <Col className="my-2 d-flex align-items-stretch" sm={12} md={6} lg={4}>
                    <Card className="text-center p-5" as={Link} to={"/pms"} style={{ textDecoration: 'none' }}>
                        <div>
                            <CardList size={40} />
                        </div>
                        <h3 className="mt-3">PMS Showcase</h3>
                        <p>Post-market surveillance: AI-based prototype evaluation of products based on medical reports or literature.</p>
                        <ButtonGroup className="mb-3">
                            <Button as={Link} to="/pms4" variant="secondary">
                                v4
                            </Button>
                            <Button as={Link} to="/pms-a" variant="secondary">
                                v4-alpine
                            </Button>
                        </ButtonGroup>
                        <p>(v4-alpine is faster but returns and explains only the first best answers)</p>
                    </Card>
                </Col>
                <Col className="my-2 d-flex align-items-stretch" sm={12} md={6} lg={4}>
                    <Card className="text-center p-5" as={Link} to={"/ca"} style={{ textDecoration: 'none' }}>
                        <div>
                            <CardList size={40} />
                        </div>
                        <h3 className="mt-3">CA Showcase</h3>
                        <p>Clinical Affairs Showcase of AI automation of the literature review process</p>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Dashboard;