import React from 'react';
import './Footer.css'
import Btt from './../resources/btt.svg'
function Footer() {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top with smooth animation
    };

    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col text-center m-5">
                        <span onClick={scrollToTop} style={{ textDecoration: 'None', cursor: 'pointer' }}>
                            <img src={Btt} alt={'back to top'} /><br/>
                            <p style={{ color: '#dc1a22', fontSize: 30, fontWeight: 4 }}>Back to top</p>
                        </span>
                    </div>
                </div>
            </div>

            <div className="mdu-footer">
                <div className="upper-part">
                    <div className="container" style={{ paddingTop: '2rem' }}>
                        <div style={{ marginBottom: '3rem' }}>
                            <p className="footer-headline"><strong>Das Instituts-Journal</strong></p>
                            <p>Der Newsletter, mit dem sich Hersteller, Behörden und Benannte Stellen<br />wöchentlich informieren.</p>
                        </div>
                        <div>
                            <a className="sign-up-btn" href="https://www.johner-institut.de/gratis-angebote/instituts-journal/">REGISTRIEREN</a>
                        </div>
                    </div>
                </div>
                <div className="middle-part">
                    <a className="mx-1" href="https://www.johner-institut.de/blog/" rel="noopener noreferrer" aria-label="Blog" target="_blank">
                        <img src={'/footer/articles.svg'} alt={'article logo'} />
                    </a>
                    <a className="mx-1" href="https://de.linkedin.com/company/johner-institut-gmbh" rel="noopener noreferrer" aria-label="Linkedin" target="_blank">
                        <img src={'/footer/linkedin.svg'} alt={'linkedin logo'} />
                    </a>
                    <a className="mx-1" href="https://www.youtube.com/channel/UCJKZSKvE6IhWk-0A0ur07OQ" rel="noopener noreferrer" aria-label="Youtube" target="_blank">
                        <img src={'/footer/youtube.svg'} alt={'youtube logo'} />
                    </a>
                    <a className="mx-1" href="https://johner-institut.podigee.io/" rel="noopener noreferrer" aria-label="Podcast" target="_blank">
                        <img src={'/footer/podcast.svg'} alt={'podcast logo'} />
                    </a>
                    <a className="mx-1" href="https://www.johner-institut.de/blog/feed/" rel="noopener noreferrer" aria-label="RSS Feed" target="_blank">
                        <img src={'/footer/feed.svg'} alt={'feed logo'} />
                    </a>
                </div>
                <div className="lower-part">
                    <span><a href="https://www.johner-institut.de/standorte/">Standorte</a></span>
                    <span>|</span>
                    <span><a href="https://www.johner-institut.de/impressum/">Impressum</a></span>
                    <span>|</span>
                    <span><a href="https://www.johner-institut.de/datenschutz/">Datenschutz</a></span>
                    <span>|</span>
                    <span><a href="https://www.johner-institut.de/agb/">AGB</a></span>
                    <span>|</span>
                    <span><a href="https://www.johner-institut.de/kontakt/">Kontakt</a></span>
                    <span>|</span>
                    <span><a href="https://elearning.johner-institut.de/de/login/">Login</a></span>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
