import React, { useState } from 'react';
import { Button, Col, Card, Form, Spinner } from 'react-bootstrap';
import { createClient } from "@supabase/supabase-js";

function Feedback() {
    const [isLoadingFeedback, setIsLoadingFeedback] = useState(false);
    const [name, setName] = useState('');
    const [rating, setRating] = useState(0);
    const [feedback, setFeedback] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const supabaseURL = process.env.REACT_APP_SUPABASE_URL;
    const supabasePublicKey = process.env.REACT_APP_SUPABASE_KEY;
    const supabase = createClient(supabaseURL, supabasePublicKey);

    const handleFeedback = async (event) => {
        event.preventDefault();
        setIsLoadingFeedback(true)
     
        const { error } = await supabase
            .from('llmradar_feedback_ca')
            .insert({
              name: name,
              stars: rating,
              feedback: feedback,
            })
        console.log(error);
     
        setTimeout(() => {
          setSubmitted(true);
          setName('');
          setRating(0);
          setFeedback('');
          setIsLoadingFeedback(false);
        }, 500);
      }

    return (
        <>
            <h3 className='text-center'>Feedback</h3>
            <Col>
                <Card className='p-5'>
                    <Form onSubmit={handleFeedback}>
                    <Form.Group className="mb-3">
                        <Form.Label><i>Name (optionally)</i>:</Form.Label>
                        <Form.Control
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label><i>Rating</i>:</Form.Label>
                        <div>
                        {[1, 2, 3, 4, 5].map((star) => (
                            <span
                            key={star}
                            className={`star ${rating >= star ? 'selected' : ''}`}
                            style={{ cursor: "pointer", fontSize: "2.5em" }}
                            onClick={() => {
                                console.log("setted rating")
                                console.log(star)
                                setRating(star)
                            }}
                            >
                            &#9733;
                            </span>
                        ))}
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label><i>Feedback</i>:</Form.Label>
                        <Form.Control
                        as="textarea"
                        rows={4}
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                        required
                        />
                    </Form.Group>
                    <Button type="submit" variant="success">
                        { isLoadingFeedback ? (
                        <>
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                            {' '}submitting
                        </>
                        ) : (
                        <>
                            Submit Feedback
                        </>
                        )
                        }
                    </Button>
                    {submitted && (
                        <span className="submitted-text">{' '}&nbsp;&nbsp;Thank you for your feedback!</span>
                    )}
                    </Form>
                </Card>
            </Col>
        </>
    );
}

export default Feedback;
