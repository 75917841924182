import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';

function ButtonGroup({ step, setStep, parsedJson, fileAssistants }) {
    return (
        <>
            {step === 1 &&
                <>
                    <Container className="d-flex justify-content-end mt-3 p-0">
                        <Button variant="primary" type="submit" disabled={parsedJson.length === 0} onClick={() => setStep(step + 1)}>
                            Next Step
                        </Button>
                    </Container>
                </>

            }

            {step === 2 &&
                <>
                    <Container className="d-flex justify-content-between mt-3 p-0">
                        <Button variant="primary" type="submit" onClick={() => setStep(step - 1)}>
                            Previous Step
                        </Button>
                        <Button variant="primary" type="submit" disabled={fileAssistants.length === 0} onClick={() => setStep(step + 1)}>
                            Next Step
                        </Button>
                    </Container>
                </>

            }

            {step === 3 &&
                <>
                    <Container className="d-flex justify-content-between mt-3 p-0">
                        <Button variant="primary" type="submit" onClick={() => setStep(step - 1)}>
                            Previous Step
                        </Button>
                        <Button variant="primary" type="submit" disabled={parsedJson === 0} onClick={() => setStep(step + 1)}>
                            Next Step
                        </Button>
                    </Container>
                </>

            }

            {step === 4 &&
                <>
                    <Container className="d-flex justify-content-between mt-3 p-0">
                        <Button variant="primary" type="submit" onClick={() => setStep(step - 1)}>
                            Previous Step
                        </Button>
                        <Link to="/">
                            <Button variant="outline-primary" type="submit">
                                Close
                            </Button>
                        </Link>
                    </Container>
                </>

            }
        </>
    );
}

export default ButtonGroup;
