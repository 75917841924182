import React from 'react';
import {Container, Navbar, Row, Col} from 'react-bootstrap';
import JILogo from '../resources/ji-logo.png'

function HeaderPms(props) {
    return (
        <header>
            <Container>
                <Row>
                    <Col className={'my-4'}>
                        <a href="https://www.johner-institut.de/">
                            <img
                                alt=""
                                src={JILogo}
                                width="200"
                                height="50"
                                className="d-inline-block align-top"
                            />
                        </a>
                    </Col>
                </Row>
            </Container>
            <Navbar style={{backgroundColor: '#dc1a22', color: 'white'}}>
                <Container>
                    <p style={{fontSize: 20}} className={'my-2'}>PMS Showcase ({props.version})</p>
                    <p className="justify-content-end mb-0">Last Update: <b>2024/01/24</b></p>
                </Container>
            </Navbar>
        </header>
    );
}

export default HeaderPms;
